import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBrain,
  faClone,
  faFlask,
  faCalculator,
} from "@fortawesome/free-solid-svg-icons";

function MechanismEvidence({ table, row }) {
  return (
    <>
      {(table[row].mechanism_evidence === "nan" ||
        table[row].mechanism_evidence === "TRUE" ||
        table[row].mechanism_evidence === "trivial") &&
      table[row].mechanism_id ? (
        <>
          <FontAwesomeIcon
            icon={faBrain}
            color="rgb(0,0,0,0.8)"
            id="seeEvizens"
          />

          <div id="terezaEvizens">
            Mechanism was based on other known <br /> mechanisms and chemical
            logic.
          </div>
        </>
      ) : table[row].mechanism_evidence === "similarity" ? (
        <>
          <FontAwesomeIcon
            icon={faClone}
            color="rgb(0,0,0,0.8)"
            id="seeEvizens"
          />

          <div id="terezaEvizens">
            Mechanism was deduced using analogy <br /> and similarity to other
            mechanisms.
          </div>
        </>
      ) : table[row].mechanism_evidence === "calculation" ? (
        <>
          <FontAwesomeIcon
            icon={faCalculator}
            color="rgb(0,0,0,0.8)"
            id="seeEvizens"
          />
          <div id="terezaEvizens">
            Mechanism was described <br /> using calculation methods.
          </div>
        </>
      ) : table[row].mechanism_evidence === "experiment" ? (
        <>
          <FontAwesomeIcon
            icon={faFlask}
            color="rgb(0,0,0,0.8)"
            id="seeEvizens"
          />
          <div id="terezaEvizens">
            Mechanism was discovered by <br /> conducting chemical experiments
          </div>
        </>
      ) : (
        <></>
      )}{" "}
    </>
  );
}

export default MechanismEvidence
