import useAxiosPrivate from "./useAxiosPrivate.js";
import useAuth from "./useAuth.js";

function useSuggestPartialForm() {
    const axiosPrivate = useAxiosPrivate()
    const {auth} = useAuth()

    async function suggestPartialForm(event,form,fullFormClicked,pubFormClicked) {
        event.preventDefault();
        const username = auth.username;
        try {
          await axiosPrivate.post("/suggestreaction", {
            link: form.link,
            pubmedId: form.pubmedId,
            doi: form.doi,
            note: form.note,
            username,
            fullFormClicked,
            pubFormClicked,
          });
          document.getElementById("overlap").style.display = "block";
          document.getElementById("submitConfirmation").style.display = "block";
          const elements = document.querySelectorAll("#body > *:not(#overlap)");
          elements.forEach((element) => {
            element.style.filter = "blur(3px)";
          });
        } catch (err) {
          throw err;
        }
      }

      return suggestPartialForm

}

export default useSuggestPartialForm