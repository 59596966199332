import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import ExternalLink from "./externalLink.js";
import QuickSearchButton from "../Home/quickSearchButton.js";

function GenbankLink({
  suggestion,
  table,
  row,
  interactiveSearch,
  isQuickSearch,
  interactiveAdvancedSearch,
  showEnzymePopups,
}) {
  const DISABLED_CONDITION = suggestion == undefined ? false : true;

  function handleClick() {
    return isQuickSearch
      ? interactiveSearch(table[row].genbank_id)
      : interactiveAdvancedSearch(showEnzymePopups,"genbank",table[row].genbank_id,false);
  }

  return (
    <>
      {table[row].genbank_id ? (
        <>
          <div style={{ position: "relative" }}>
            Genbank ID:{" "}
            <ExternalLink
              idLink={"navigateGenbank"}
              idDiv={`goToGenbank${row}`}
              icon={faExternalLink}
              externalLink={`https://www.ncbi.nlm.nih.gov/protein/${table[row].genbank_id}`}
            >
              Find in NCBI
            </ExternalLink>
            <QuickSearchButton
              id={"genbankQuickSearch"}
              disabled={DISABLED_CONDITION}
              onClick={handleClick}
            >
              {table[row].genbank_id}
            </QuickSearchButton>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default GenbankLink;
