import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import MoleculeNameButton from "./moleculeNameButton.js";
import SmilesChebiRow from "./smilesChebiRow.js";
import CopySmilesButton from "./copySmilesButton.js";
import ExternalLink from "./externalLink.js";


function ProductRow({
  suggestion,
  table,
  row,
  interactiveSearch,
  isQuickSearch,
  interactiveAdvancedSearch,
  showProductPopups
}) {

  const DISABLED_CONDITION = suggestion == undefined ? false : true;

  function copySmiles(id) {
    let smiles = document.getElementById(id).innerText;
    let elem = document.createElement("textarea");
    document.body.appendChild(elem);
    smiles = smiles.trim();
    elem.value = smiles;
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
  }

  function handleClick() {
    return isQuickSearch
      ? interactiveSearch(table[row].product_name)
      : interactiveAdvancedSearch(showProductPopups,"prodName",table[row].product_name,false);
  }

  return (
    <>
      {table[row].product_smiles ? (
        <>
          <MoleculeNameButton
            id={"productNameQuickSearch"}
            disabled={DISABLED_CONDITION}
            onClick={handleClick}
          >
            {table[row].product_name}
          </MoleculeNameButton>

          <SmilesChebiRow>
            <CopySmilesButton table={table} row={row} copySmiles={copySmiles} substrateOrProduct={"product"}/>
            <li
              style={{ marginRight: 5, position: "relative" }}
              className="chebiLink"
            >
              {table[row].product_chebi ? (
                <>
                  <ExternalLink
                    externalLink={`https://www.ebi.ac.uk/chebi/searchId.do?chebiId=CHEBI:${table[row].product_chebi}`}
                    icon={faExternalLink}
                    idDiv={`goToChebiProduct${row}`}
                  >
                    Find in ChEBI: {table[row].product_chebi}
                  </ExternalLink>
                </>
              ) : (
                <></>
              )}
            </li>
          </SmilesChebiRow>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default ProductRow;
