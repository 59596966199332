function AboutThisProject({}) {
  return (
    <div id="Information">
      <h3>About this project</h3>
      <p>
        Database of Mechanisms And Reactions of Terpene Synthases (MARTS-DB) is
        a project founded at the Institute of Organic Chemistry and Biochemistry
        in Prague with the aim of cataloging all known terpene synthases, their
        reactions and reaction mechanisms.{" "}
      </p>
      <p>
        The project originaly started as an internal datataset built to train
        predictive machine learning models. Today the data gathered can be
        browsed and also expanded by a wide community.
      </p>
    </div>
  );
}

export default AboutThisProject;
