

function MechanismStep({
  children,
  updateSmiles,
  updateName,
  updateChebi,
  updateType,
  form,
  number,
  inputCount,
}) {
    const DISABLED_CONDITION_FIRST = number === 1 ? true : false
    const STYLE_FIRST = number === 1 ? { backgroundColor: "rgb(220,220,220)" } : { backgroundColor: "rgb(245,245,245)" }
    const STYLE_LAST = number === inputCount ? { backgroundColor: "rgb(220,220,220)" } : { backgroundColor: "rgb(245,245,245)" }
    const DISABLED_CONDITION_LAST = number === inputCount ? true : false

  return (
    <>
      <h5 id="heading1" align="left">
        {children}
      </h5>

      <label id={`labelsubstrate${number}`} for={`newinputsubstrate${number}`}>
        Substrate SMILES:
      </label>
      <textarea
        key="substrateIntermediate"
        id={`newinputsubstrate${number}`}
        value={form.substrateIntermediate[number - 1]}
        name={number - 1}
        onChange={updateSmiles}
        disabled={DISABLED_CONDITION_FIRST}
        style={STYLE_FIRST}
      />
      <label id={`labelproduct${number}`} for={`newinputproduct${number}`}>
        Product SMILES:
      </label>
      <textarea
      key="productIntermediate"
        id={`newinputproduct${number}`}
        value={form.productIntermediate[number]}
        name={number}
        onChange={updateSmiles}
        disabled={DISABLED_CONDITION_LAST}
        style={STYLE_LAST}
      />
      <label id={`labelname${number}`} for={`newinputname${number}`}>
        Product name:
      </label>
      <input
      key="name"
        id={`newinputname${number}`}
        name={number}
        value={form.name[number]}
        onChange={updateName}
        disabled={DISABLED_CONDITION_LAST}
        style={STYLE_LAST}
      />
      <label id={`labelchebi${number}`} for={`newinputchebi${number}`}>
        Product ChEBI:
      </label>
      <input
      key="chebi"
        id={`newinputchebi${number}`}
        name={number}
        value={form.chebi[number]}
        onChange={updateChebi}
        disabled={DISABLED_CONDITION_LAST}
        style={STYLE_LAST}
      />
      <label id={`labeltype${number}`} for={`newinputtype${number}`}>
        Step type:
      </label>
      <input
      key="type"
        id={`newinputtype${number}`}
        name={number}
        value={form.type[number]}
        onChange={updateType}
      />
    </>
  );
}

export default MechanismStep;
