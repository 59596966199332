import useAxiosPrivate from "./useAxiosPrivate.js";
import useAuth from "./useAuth.js";
import encoding from "encoding";

function useSuggestFullForm() {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  async function suggestFullForm(event, form, fullFormClicked, pubFormClicked) {
    event.preventDefault();
    const username = auth.username;
    try {
      await axiosPrivate.post("/suggestreaction", {
        form: {
          ...form,
          reactSmiles: encoding.convert(form.reactSmiles, "Latin_1"),
          prodSmiles: encoding.convert(form.prodSmiles, "Latin_1"),
          cosubstrateSmiles: encoding.convert(
            form.cosubstrateSmiles,
            "Latin_1"
          ),
        },
        fullFormClicked,
        pubFormClicked,
        username,
      });
      document.getElementById("overlap").style.display = "block";
      document.getElementById("submitConfirmation").style.display = "block";
      const elements = document.querySelectorAll("#body > *:not(#overlap)");
      elements.forEach((element) => {
        element.style.filter = "blur(3px)";
      });
    } catch (err) {
      throw err;
    }
  }

  return suggestFullForm;
}

export default useSuggestFullForm;
