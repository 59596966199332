export function noButton(setForm) {
    document.getElementById("overlap").style.display = "none";
    document.getElementById("addMechanism").style.display = "none";
    const elements = document.querySelectorAll("#body > *:not(#overlap)");
    elements.forEach((element) => {
      element.style.filter = "unset";
    });
    setForm({
      animaliaMammal: "",
      animaliaCoral: "",
      animaliaBird: "",
      animaliaMarineSponge: "",
      animaliaInsect: "",
      animaliaMammalHuman: "",
      fungi: "",
      amoebozoa: "",
      plantae: "",
      bacteria: "",
      cyanobacteria: "",
      plantaeRedAlgae: "",
      viruses: "",
      archaea: "",
      di: "",
      mono: "",
      sesq: "",
      tri: "",
      tetra: "",
      sester: "",
      sesquar: "",
      prenyl: "",
      hemi: "",
      one: "",
      two: "",
      species: "",
      enzymeName: "",
      AASequence: "",
      uniprot: "",
      genbank: "",
      reactSmiles: "",
      prodSmiles: "",
      reactName: "",
      prodName: "",
      reactChebi: "",
      prodChebi: "",
      cosubstrateName: "",
      cosubstrateChebi: "",
      cosubstrateSmiles: "",
      link: "",
      pubmedId: "",
      doi: "",
    });
    const checkboxArray = [
      "MonoTerpInput",
      "SesqTerpInput",
      "TriTerpInput",
      "TetraTerpInput",
      "SesterTerpInput",
      "SesquarTerpInput",
      "HemiTerpInput",
      "PrenylTerpInput",
      "DiTerpInput",
      "ClassI",
      "ClassII",
      "PLantaeInput",
      "AnimaliaCoralInput",
      "AnimaliaBirdInput",
      "AnimaliaMammalInput",
      "AnimaliaMammalHumanInput",
      "AnimaliaInsectInput",
      "BacteriaInput",
      "FungiInput",
      "AnimaliaMarineSpongeInput",
      "ArchaeaInput",
      "AmoebozoaInput",
      "VirusesInput",
      "CyanobacteriaInput",
      "PlantaeRedAlgaeInput",
    ];
    checkboxArray.forEach((element) => {
      if (element.checked) {
        element.checked = false;
      }
    });
  }


export function yesButton() {
    document.getElementById("MechanismInput").style.display = "block";
  }


export function submitOK() {
    if (document.getElementById("submitConfirmation")) {
      document.getElementById("submitConfirmation").style.display = "none";
    }
    if (document.getElementById("duplication")) {
      document.getElementById("duplication").style.display = "none";
    }
    document.getElementById("addMechanism").style.display = "block";
  }