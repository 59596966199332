function Page({ pageNumber, buttonNumber, goToPage, pageCount, goToPageNumber, distanceFromEnd, middleDistance }) {
  return (
    <li>
      {pageNumber < buttonNumber ? ( //dataCount <= 10
        ""
      ) : pageCount > 4 ? ( 
        pageCount > pageNumber - 3 && pageNumber <= 7 ? (
          <button
            onClick={() => {
              goToPage(goToPageNumber);
            }}
            id={`pages${buttonNumber}`}
          >
            {buttonNumber}
          </button>
        ) : pageCount > pageNumber - 3 ? (
          <button
            onClick={() => {
              goToPage(goToPageNumber);
            }}
            id={`pages${buttonNumber}`}
          >
            {pageNumber - distanceFromEnd}
          </button>
        ) : (
          <button
            onClick={() => {
              goToPage(goToPageNumber);
            }}
            id={`pages${buttonNumber}`}
          >
            {pageCount - middleDistance}
          </button>
        )
      ) : (
        <button
          onClick={() => {
            goToPage(goToPageNumber);
          }}
          id={`pages${buttonNumber}`}
        >
          {buttonNumber}
        </button>
      )}
    </li>
  );
}

export default Page;
