import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

function SmallLoading({}) {
  return (
    <div
      style={{
        textAlign: "center",
        backgroundSize: "cover",
        padding: 154,
      }}
    >
      <Spin
        indicator={
          <LoadingOutlined
            style={{ fontSize: 50, color: "rgb(23, 55, 151)" }}
            spin
          />
        }
      />
    </div>
  );
}

export default SmallLoading
