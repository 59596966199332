import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import QuickSearchButton from "../Home/quickSearchButton.js";
import ExternalLink from "./externalLink.js";

function UniprotLink({
  suggestion,
  table,
  row,
  interactiveSearch,
  isQuickSearch,
  interactiveAdvancedSearch,
  showEnzymePopups,
}) {
  const DISABLED_CONDITION = suggestion == undefined ? false : true;

  function handleClick() {
    return isQuickSearch
      ? interactiveSearch(table[row].uniprot_id)
      : interactiveAdvancedSearch(showEnzymePopups,"uniprot",table[row].uniprot_id,false);
  }

  return (
    <>
      {table[row].uniprot_id ? (
        <>
          <div>
            Uniprot ID:{" "}
            <ExternalLink
              idLink={"navigateUniprot"}
              idDiv={`goToUniprot${row}`}
              icon={faExternalLink}
              externalLink={`https://www.uniprot.org/uniprotkb/${table[row].uniprot_id}/entry`}
            >
              Find in Uniprot
            </ExternalLink>
            <QuickSearchButton
              id={"uniprotQuickSearch"}
              disabled={DISABLED_CONDITION}
              onClick={handleClick}
            >
              {table[row].uniprot_id}
            </QuickSearchButton>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default UniprotLink;
