import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck,faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";

function FiltersMinimized({filtersVisible,dataCount,showFilters}) {
  return (
    <>
      {!filtersVisible ? (
        <div id="filtersMinimized">
          <ul align="left" id="filtersMinimizedUl">
            <li>
              <FontAwesomeIcon icon={faCheck} id="tick" /> {dataCount}
            </li>
            <li>
              <button
                type="button"
                id="DoubleDown"
                onClick={showFilters}
              >
                <FontAwesomeIcon icon={faAngleDoubleDown} id="arrow" />
              </button>
              <p id="scrollDownNote">Show filters</p>
            </li>
          </ul>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default FiltersMinimized;
