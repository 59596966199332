import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

function NoSuggestionMessage({}) {
  return (
    <div align="center">
      <h1>
        <FontAwesomeIcon icon={faExclamationCircle} color="red" />
      </h1>
      <h1>No suggestions found!</h1>
      <p>
        You have not posted any suggestions yet. Start by submitting your first
        suggestion <Link to="/suggestreaction">here</Link>.{" "}
      </p>
    </div>
  );
}

export default NoSuggestionMessage
