

function dataToCsv(data){
let res = "Uniprot_ID,Genbank_ID,Enzyme_name,Aminoacid_sequence,Species,Kingdom,Type,Class,Substrate_name,Substrate_smiles,Substrate_chebi_ID,Product_name,Product_smiles,Product_chebi_id,Reaction_has_mechanism,Publication\n"
data.forEach((element) => {
    let has_mechanism = (element.mechanism_id != null);
    let substrate_name = element.substrate_name
    let substrate_smiles = element.substrate_smiles
    let substrate_chebi = element.substrate_chebi
    if (element.cosubstrates.length != 0){
        element.cosubstrates.forEach((cosubs) => {
            substrate_name = substrate_name + ";" + cosubs.name;
            substrate_smiles = substrate_smiles + ";" + cosubs.smiles;
            substrate_chebi = substrate_chebi + ";" + cosubs.chebi;
        })
    }
    let new_line = `"${element.uniprot_id}","${element.genbank_id}","${element.name}","${element.aminoacid_sequence}","${element.species}","${element.kingdom}","${element.type}","${element.class}","${substrate_name}","${substrate_smiles}","${substrate_chebi}","${element.product_name}","${element.product_smiles}","${element.product_chebi}",${has_mechanism},"${element.reaction_link}"\n`
    res = res + new_line
}); 
return res
} 

export default dataToCsv