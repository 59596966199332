import TypeClassButton from "./typeClassButton.js";

function TypeClass({
  suggestion,
  table,
  row,
  interactiveSearch,
  isQuickSearch,
  interactiveAdvancedSearch,
  showEnzymePopups,
}) {
  const DISABLED_CONDITION = suggestion == undefined ? false : true;

  const TypeObject = {
    di: ["di", "Diterpene synthases", "DiTerpInput"],
    mono: ["mono", "Monoterpene synthases", "MonoTerpInput"],
    sesq: ["sesq", "Sesquiterpene synthases", "SesqTerpInput"],
    tetra: ["tetra", "Tetraterpene synthases", "TetraTerpInput"],
    sester: ["sester", "Sesterterpene synthases", "SesterTerpInput"],
    sesquar: ["sesquar", "Sesquarterpene synthases", "SesquarTerpInput"],
    tri: ["tri", "Triterpene synthases", "TriTerpInput"],
    prenyl: ["pt", "Prenyl transferases", "PrenylTerpInput"],
    hemi: ["hemi", "Hemiterpene synthases", "HemiTerpInput"],
  };

  const ClassObject = {
    one: [1, `Class I`,"ClassI"],
    two: [2, `Class II`,"ClassII"],
  };

  function handleClickType(type, key, value, input) {
    return isQuickSearch
      ? interactiveSearch(type)
      : interactiveAdvancedSearch(showEnzymePopups, key, value, input);
  }

  function handleClickClass(classNumber, key, value, input) {
    return isQuickSearch
      ? interactiveSearch(classNumber)
      : interactiveAdvancedSearch(showEnzymePopups, key, value, input);
  }

  return (
    <div>
      {Object.entries(TypeObject).map(([key, value]) => {
        if (value[0] === table[row].type) {
          let type = value[1];
          return (
            <>
              <TypeClassButton
                id={"typeQuickSearch"}
                disabled={DISABLED_CONDITION}
                onClick={() => handleClickType(type, key, value[0], value[2])}
              >
                {type}
              </TypeClassButton>
            </>
          );
        }
      })}
      {!table[row].type || !table[row].class ? ("") : (", ")}
      {Object.entries(ClassObject).map(([key, value]) => {
        if (table[row].type != "pt") {
          if (value[0] === table[row].class) {
            let classNumber = value[1];
            return (
              <>
                <TypeClassButton
                  id={"classQuickSearch"}
                  disabled={DISABLED_CONDITION}
                  onClick={() =>
                    handleClickClass(classNumber, key, `${value[0]}`, value[2])
                  }
                >
                  {classNumber}
                </TypeClassButton>
              </>
            );
          }
        }
      })}
    </div>
  );
}

export default TypeClass;
