function PartialOrFullSuggestion({pubFormUnclicked,fullFormUnclicked,unblurFullForm,unblurPubForm}) {
  return (
    <>
      <ul
        style={{
          listStyle: "none",
          padding: 0,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <li>
          <button
            id="suggestpubbutton"
            onClick={unblurPubForm}
            key={pubFormUnclicked}
          >
            Suggest by posting a publication reference
          </button>
        </li>
        <li>
          <button
            id="suggestdetailbutton"
            onClick={unblurFullForm}
            key={fullFormUnclicked}
          >
            Suggest by typing the reaction and its details
          </button>
        </li>
      </ul>
    </>
  );
}

export default PartialOrFullSuggestion
