import "./AdvancedSearch.css";
import "./SuggestMechanism.css";
import React, { useState } from "react";
import Footer from "../Components/Footer.js";
import ReactionTable from "../reactionTable.js";
import HomeNavbar from "../HomeNavbar.js";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import PageCounter from "../Components/pageCounter/pageCounter.js";
import { useEffect } from "react";
import useAuth from "../hooks/useAuth.js";
import searchSuggestMechanism from "../api/searchSuggestMechanism.js";
import AdvancedFilters from "../Components/AdvancedSearch/advancedFilters.js";
import KingdomFilters from "../Components/AdvancedSearch/kingdomFilters.js";
import TypeFilters from "../Components/AdvancedSearch/typeFilters.js";
import ClassFilters from "../Components/AdvancedSearch/classFilters.js";
import CheckboxDiv from "../Components/AdvancedSearch/checkboxDiv.js";
import SearchClearMechanismButtons from "../Components/AdvancedSearch/searchClearMechanismButtons.js";
import ResultBar from "../Components/AdvancedSearch/resultBar.js";
import FiltersMinimized from "../Components/AdvancedSearch/filtersMinimized.js";
import { showInput } from "../functions/showInputFunctions.js";
import {
  clearAllInputs,
  clearEnzymeInputs,
  clearProductInputs,
  clearSubstrateInputs,
} from "../functions/clearInputsFunctions.js";
import SuggestionInfo from "../Components/Suggestions/suggestionInfo.js";
import renderMechanismSuggestion from "../api/renderMechanismSuggestion.js";
import { showSuggest } from "../functions/showInputFunctions.js";
import useSubmitSuggestedMechLink from "../hooks/useSubmitSuggestedMechLink.js";
import useDidUpdateEffect from "../hooks/useDidUpdateEffect.js";
import useSearchParamsState from "../hooks/useSearchParamsState.js";
import { useSearchParams } from "react-router-dom";

function SuggestMechanism() {
  let [count, setCount] = useState(0);
  const [filtersVisible, setFiltersVisible] = useState(true);
  const [table, setTable] = useState([]);
  const [clearAll, setClearAll] = useState(false);
  const [suggestionCount, setSuggestionCount] = useState(0);
  const [suggestUnclicked, setSuggestUnlicked] = useState(true);
  const [pageCount, setPageCount] = useSearchParamsState("page",1);
  const [buttonCount, setButtonCount] = useState(1);
  const [dataCount, setDataCount] = useState(0);
  const [enzymeInputClicked, setEnzymeInputClicked] = useState(false);
  const [reactantInputClicked, setReactantInputClicked] = useState(false);
  const [productInputClicked, setProductInputClicked] = useState(false);
  const [enzymeInputUnclicked, setEnzymeInputUnclicked] = useState(true);
  const [reactantInputUnclicked, setReactantInputUnclicked] = useState(true);
  const [productInputUnclicked, setProductInputUnclicked] = useState(true);
  const [searchSubmit, setSearchSubmit] = useState(false);
  const [searchResult, setSearchResult] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [interactiveSearchSwitch, setInteractiveSearchSwitch] = useState(false);
  const [triggerUseEffect, setTriggerUseEffect] = useState(false);
  const [searchParams] = useSearchParams()
  const [form, setForm] = useSearchParamsState("filters",{
    name: "",
    animaliaMammal: "",
    animaliaCoral: "",
    animaliaBird: "",
    animaliaMarineSponge: "",
    animaliaInsect: "",
    animaliaMammalHuman: "",
    fungi: "",
    amoebozoa: "",
    plantae: "",
    bacteria: "",
    cyanobacteria: "",
    plantaeRedAlgae: "",
    viruses: "",
    archaea: "",
    di: "",
    mono: "",
    sesq: "",
    tri: "",
    tetra: "",
    sester: "",
    sesquar: "",
    prenyl: "",
    hemi: "",
    one: "",
    two: "",
    uniprot: "",
    genbank: "",
    reactSmiles: "",
    prodSmiles: "",
    reactName: "",
    prodName: "",
    reactChebi: "",
    prodChebi: "",
  });

  const axiosPrivate = useAxiosPrivate();
  const submitSuggest = useSubmitSuggestedMechLink();
  const { auth } = useAuth();
  const isQuickSearch = false;
  const isSuggestion = true

  const enzymeInputs = [
    "NameInput",
    "TypeInput",
    "ClassInput",
    "UniprotInput",
    "GenbankInput",
    "KingdomInput",
  ];
  const reactantInputs = [
    "ReactantSMILESInput",
    "ReactantChebiInput",
    "ReactantNameInput",
  ];
  const productInputs = [
    "ProductSMILESInput",
    "ProductChebiInput",
    "ProductNameInput",
  ];

  function showEnzymeInput() {
    showInput(
      enzymeInputs,
      "enzymeDD",
      enzymeInputClicked,
      setEnzymeInputClicked,
      "EnzymeInputs",
      setEnzymeInputUnclicked
    );
  }

  function showEnzymePopups() {
    if (!enzymeInputClicked) {
      showInput(
        enzymeInputs,
        "enzymeDD",
        enzymeInputClicked,
        setEnzymeInputClicked,
        "EnzymeInputs",
        setEnzymeInputUnclicked
      );
    }
  }

  function showSubstrateInput() {
    showInput(
      reactantInputs,
      "reactantDD",
      reactantInputClicked,
      setReactantInputClicked,
      "ReactantInputs",
      setReactantInputUnclicked
    );
  }

  function showSubstratePopups() {
    if (!reactantInputClicked) {
      showInput(
        reactantInputs,
        "reactantDD",
        reactantInputClicked,
        setReactantInputClicked,
        "ReactantInputs",
        setReactantInputUnclicked
      );
    }
  }

  function showProductInput() {
    showInput(
      productInputs,
      "productDD",
      productInputClicked,
      setProductInputClicked,
      "ProductInputs",
      setProductInputUnclicked
    );
  }

  function showProductPopups() {
    if (!productInputClicked) {
      showInput(
        productInputs,
        "productDD",
        productInputClicked,
        setProductInputClicked,
        "ProductInputs",
        setProductInputUnclicked
      );
    }
  }

  function hideFilters() {
    setFiltersVisible(false);
    document.getElementById("sectionFilters").style.display = "none";
  }

  function showFilters() {
    setFiltersVisible(true);
    document.getElementById("sectionFilters").style.display = "block";
  }

  function handleClearEnzymeInputs(event) {
    event.preventDefault();
    clearEnzymeInputs(setForm);
    setInteractiveSearchSwitch((current) => !current);
    setClearAll((current) => !current);
  }

  function handleClearSubstrateInputs(event) {
    event.preventDefault();
    clearSubstrateInputs(setForm);
    setInteractiveSearchSwitch((current) => !current);
    setClearAll((current) => !current);
  }

  function handleClearProductInputs(event) {
    event.preventDefault();
    clearProductInputs(setForm);
    setInteractiveSearchSwitch((current) => !current);
    setClearAll((current) => !current);
  }

  function handleClear(event) {
    event.preventDefault();
    clearAllInputs(setForm);
    setInteractiveSearchSwitch((current) => !current);
    setClearAll((current) => !current);
  }

  function seeSuggestionInput(row) {
    showSuggest(row,suggestionCount,setSuggestUnlicked,"Full")
  }

  function submitSuggestionInput(row) {
    submitSuggest(row,table)
  }

  function handleSearch(event) {
    event.preventDefault();
    if (pageCount == 1) {
      setTriggerUseEffect((current) => !current);
    } else {
      setInteractiveSearchSwitch((current) => !current);
    }
  }

  function updateForm(event) {
    setForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  }

  async function interactiveAdvancedSearch(
    showSpecificPopups,
    key,
    value,
    idInput
  ) {
    try {
      showSpecificPopups();
      if (idInput) {
        document.getElementById(idInput).checked = true;
      }
      setForm((prev) => ({
        ...prev,
        [key]: value,
      }));
      if (pageCount == 1) {
        setTriggerUseEffect((current) => !current);
      } else {
        setInteractiveSearchSwitch((current) => !current);
      }
    } catch (err) {
      throw err;
    }
  }

  async function loadData() {
    const table = await searchSuggestMechanism({
      form,
      pageCount,
      buttonCount,
      setIsLoading,
      setSearchSubmit,
      setDataCount,
      axios:axiosPrivate
    });
    setTable(table)
    const body = await renderMechanismSuggestion({
      table,
      seeSuggestionInput,
      submitSuggestionInput,
      showEnzymePopups,
      showProductPopups,
      showSubstratePopups,
      interactiveAdvancedSearch,
      suggestUnclicked,
      isQuickSearch
    });
    setSuggestionCount(table.length);
    setSearchResult(body);
    setSearchSubmit(true);
    setIsLoading(false);
  }

  useEffect(() => {
    loadData();
  }, [suggestionCount, pageCount, triggerUseEffect,clearAll]);

  useDidUpdateEffect(() => {
    setButtonCount(1);
    setPageCount(1);
  }, [interactiveSearchSwitch]);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
      </head>
      <body id="body">
        <HomeNavbar suggestionCount={suggestionCount} />
        <section id="sectionFilters">
          <div style={{ paddingTop: 10 }} id="content">
            <div id="filtersMargins">
              <form
                id="inputs"
                onSubmit={(e) => {
                  e.preventDefault();
                  loadData();
                }}
              >
                <SuggestionInfo />
                <div id="filters">
                  <ul style={{ listStyle: "none" }}>
                    <AdvancedFilters
                      id={"EnzymeInputs"}
                      idDD={"enzymeDD"}
                      unclickedState={enzymeInputUnclicked}
                      handleShowInputs={showEnzymeInput}
                      handleClearFilters={handleClearEnzymeInputs}
                    >
                      Enzyme filters:
                    </AdvancedFilters>
                    <div id="allEnzymeInputs">
                      <input
                        id="NameInput"
                        placeholder="Enzyme name"
                        name="name"
                        style={{ display: "none", marginBottom: 10 }}
                        onChange={updateForm}
                        value={form.name}
                      />
                      <CheckboxDiv id={"KingdomInput"}>
                        <KingdomFilters updateForm={updateForm} searchParams={searchParams}/>
                      </CheckboxDiv>
                      <CheckboxDiv id={"TypeInput"}>
                        <TypeFilters updateForm={updateForm} searchParams={searchParams}/>
                      </CheckboxDiv>
                      <CheckboxDiv id={"ClassInput"}>
                        <ClassFilters updateForm={updateForm} searchParams={searchParams}/>
                      </CheckboxDiv>

                      <input
                        id="UniprotInput"
                        placeholder="Uniprot ID"
                        style={{ display: "none" }}
                        name="uniprot"
                        onChange={updateForm}
                        value={form.uniprot}
                      />
                      <input
                        id="GenbankInput"
                        placeholder="Genbank ID"
                        style={{ display: "none" }}
                        name="genbank"
                        onChange={updateForm}
                        value={form.genbank}
                      />
                    </div>
                    <AdvancedFilters
                      id={"ReactantInputs"}
                      idDD={"reactantDD"}
                      unclickedState={reactantInputUnclicked}
                      handleShowInputs={showSubstrateInput}
                      handleClearFilters={handleClearSubstrateInputs}
                    >
                      Substrate filters:
                    </AdvancedFilters>
                    <textarea
                      id="ReactantSMILESInput"
                      placeholder="Substrate SMILES"
                      style={{ display: "none" }}
                      name="reactSmiles"
                      onChange={updateForm}
                      value={form.reactSmiles}
                    />
                    <input
                      id="ReactantNameInput"
                      placeholder="Substrate name"
                      style={{ display: "none" }}
                      name="reactName"
                      onChange={updateForm}
                      value={form.reactName}
                    />
                    <input
                      id="ReactantChebiInput"
                      placeholder="Substrate ChEBI"
                      style={{ display: "none" }}
                      name="reactChebi"
                      onChange={updateForm}
                      value={form.reactChebi}
                    />
                    <AdvancedFilters
                      id={"ProductInputs"}
                      idDD={"productDD"}
                      unclickedState={productInputUnclicked}
                      handleShowInputs={showProductInput}
                      handleClearFilters={handleClearProductInputs}
                    >
                      Product filters:
                    </AdvancedFilters>
                    <textarea
                      id="ProductSMILESInput"
                      placeholder="Product SMILES"
                      style={{ display: "none" }}
                      name="prodSmiles"
                      onChange={updateForm}
                      value={form.prodSmiles}
                    />
                    <input
                      id="ProductNameInput"
                      placeholder="Product name"
                      style={{ display: "none" }}
                      name="prodName"
                      onChange={updateForm}
                      value={form.prodName}
                    />
                    <input
                      id="ProductChebiInput"
                      placeholder="Product ChEBI"
                      style={{ display: "none" }}
                      name="prodChebi"
                      onChange={updateForm}
                      value={form.prodChebi}
                    />

                    <SearchClearMechanismButtons
                      handleClear={handleClear}
                      handleSearch={handleSearch}
                      isSuggestion={isSuggestion}
                    />
                  </ul>
                </div>
                <ResultBar
                  isLoading={isLoading}
                  filtersVisible={filtersVisible}
                  dataCount={dataCount}
                  searchSubmit={searchSubmit}
                  hideFilters={hideFilters}
                />
              </form>
            </div>
          </div>
        </section>
        <FiltersMinimized
          filtersVisible={filtersVisible}
          dataCount={dataCount}
          showFilters={showFilters}
        />

        <section id="tableSection">
          <div id="table" style={{ paddingTop: 50 }}>
            {!searchSubmit ? (
              ""
            ) : searchResult.length == 0 ? (
              ""
            ) : (
              <>
                <ReactionTable
                  body={searchResult}
                  display={"none"}
                  auth={auth}
                />
               </>
            )}
          </div>
        </section>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ul
            style={{
              listStyle: "none",
              padding: 0,
              display: "flex",
              justifyContent: "space-between",
              marginTop: 50,
            }}
          >
            <li></li>
            <li>
              <PageCounter
                dataCount={dataCount}
                buttonCount={buttonCount}
                setButtonCount={setButtonCount}
                pageCount={pageCount}
                setPageCount={setPageCount}
                setCount={setCount}
                count={count}
              />
            </li>
            <li></li>
          </ul>
        </div>
        <div id="footer">
          <Footer />
        </div>
      </body>
    </html>
  );
}

export default SuggestMechanism;
