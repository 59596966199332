import ClearAdvancedFilters from "./clearAdvancedFilters.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";

function AdvancedFilters({
  id,
  unclickedState,
  handleShowInputs,
  children,
  idDD,
  handleClearFilters,
}) {
  return (
    <li>
      <ul
        style={{
          listStyle: "none",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div id={id} key={unclickedState} onClick={handleShowInputs}>
          <li>
            <h5>{children}</h5>
          </li>
          <li>
            <FontAwesomeIcon id={idDD} icon={faAngleDoubleDown} />
          </li>
        </div>
        <ClearAdvancedFilters handleClearFilters={handleClearFilters}>
          Clear inputs
        </ClearAdvancedFilters>
      </ul>
    </li>
  );
}

export default AdvancedFilters;
