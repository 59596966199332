function CheckboxInput({
  idLabel,
  idInput,
  inputText,
  name,
  value,
  updateForm,
  searchParams
}) {
  return (
    <label id={idLabel} for={idInput}>
      <input
        type="checkbox"
        id={idInput}
        value={document.getElementById(idInput)?.checked === false ? value : ""}
        checked={searchParams?.get(`${name}`) ? true : false}
        name={name}
        onClick={updateForm}
      />
      {inputText}
    </label>
  );
}

export default CheckboxInput;
