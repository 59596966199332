function TypeClassButton({id,disabled,children,onClick}) {
    return (
        <button
        id={id}
        disabled={disabled}
        style={{
          border: "none",
          backgroundColor: "rgb(245,245,245)",
          padding: 0,
        }}
        onClick={onClick}
      >
        {children}
      </button>
    )
}

export default TypeClassButton