import React, { useState } from "react";
import "./SuggestReaction.css";
import Footer from "../Components/Footer.js";
import { faCheck, faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth.js";
import HomeNavbar from "../HomeNavbar.js";
import { useEffect } from "react";
import KingdomFilters from "../Components/AdvancedSearch/kingdomFilters.js";
import ClassFilters from "../Components/AdvancedSearch/classFilters.js";
import TypeFilters from "../Components/AdvancedSearch/typeFilters.js";
import {
  initialBlur,
  seeFullForm,
  seePubForm,
} from "../functions/unblurInputFunctions.js";
import useSuggestPartialForm from "../hooks/useSuggestPartialForm.js";
import useSuggestFullForm from "../hooks/useSuggestFullForm.js";
import {
  submitOK,
  noButton,
  yesButton,
} from "../functions/helperSuggestFunction.js";
import SuggestionInfo from "../Components/Suggestions/suggestionInfo.js";
import PartialOrFullSuggestion from "../Components/Suggestions/partialOrFullSuggestion.js";
import SubmitPartialOrFullForm from "../Components/Suggestions/submitPartialOrFullForm.js";
import TextInputOptional from "../Components/Suggestions/textInputOptional.js";
import TextInputMandatory from "../Components/Suggestions/textInputMandatory.js";
import TextAreaMandatory from "../Components/Suggestions/textAreaMandatory.js";

function SuggestReaction() {
  const [validChebiReactant, setValidChebiReactant] = useState(true);
  const [validChebiProduct, setValidChebiProduct] = useState(true);
  const [validChebiCosubstrate, setValidChebiCosubstrate] = useState(true);
  const [mechanismReference, setMechanismReference] = useState("");
  const [noteLength, setNoteLength] = useState(0);
  const [pubFormClicked, setPubFormClicked] = useState(false);
  const [pubFormUnclicked, setPubFormUnclicked] = useState(true);
  const [fullFormClicked, setFullFormClicked] = useState(false);
  const [fullFormUnclicked, setFullFormUnclicked] = useState(true);
  const [form, setForm] = useState({
    animaliaMammal: "",
    animaliaCoral: "",
    animaliaBird: "",
    animaliaMarineSponge: "",
    animaliaInsect: "",
    animaliaMammalHuman: "",
    fungi: "",
    amoebozoa: "",
    plantae: "",
    bacteria: "",
    cyanobacteria: "",
    plantaeRedAlgae: "",
    viruses: "",
    archaea: "",
    di: "",
    mono: "",
    sesq: "",
    tri: "",
    tetra: "",
    sester: "",
    sesquar: "",
    prenyl: "",
    hemi: "",
    one: "",
    two: "",
    species: "",
    enzymeName: "",
    AASequence: "",
    uniprot: "",
    genbank: "",
    reactSmiles: "",
    prodSmiles: "",
    reactName: "",
    prodName: "",
    reactChebi: "",
    prodChebi: "",
    cosubstrateName: "",
    cosubstrateChebi: "",
    cosubstrateSmiles: "",
    link: "",
    pubmedId: "",
    doi: "",
  });

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const suggestPartialForm = useSuggestPartialForm();
  const suggestFullForm = useSuggestFullForm();

  const CHEBI_REGEX = /^[0-9]{0,50}$/;

  useEffect(() => {
    setValidChebiReactant(CHEBI_REGEX.test(form.reactChebi));
  }, [form.reactChebi]);

  useEffect(() => {
    setValidChebiCosubstrate(CHEBI_REGEX.test(form.cosubstrateChebi));
  }, [form.cosubstrateChebi]);

  useEffect(() => {
    setValidChebiProduct(CHEBI_REGEX.test(form.prodChebi));
  }, [form.prodChebi]);

  function unblurFullForm(event) {
    seeFullForm(
      event,
      fullFormClicked,
      setFullFormClicked,
      setFullFormUnclicked,
      pubFormClicked,
      setPubFormClicked,
      setPubFormUnclicked
    );
  }

  function unblurPubForm(event) {
    seePubForm(
      event,
      fullFormClicked,
      setFullFormClicked,
      setFullFormUnclicked,
      pubFormClicked,
      setPubFormClicked,
      setPubFormUnclicked
    );
  }

  async function submitMechanism() {
    const username = auth.username;
    try {
      await axiosPrivate.post("/mechanismlink", {
        mechanismReference,
        username,
      });
    } catch (err) {
      throw err;
    } finally {
      navigate("/mysuggestions");
    }
  }

  function updateForm(event) {
    setForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  }

  function handleSuggestPartial(event) {
    return suggestPartialForm(event, form, fullFormClicked, pubFormClicked);
  }

  function handleSuggestFull(event) {
    return suggestFullForm(event, form, fullFormClicked, pubFormClicked);
  }

  useEffect(() => {
    initialBlur();
  }, []);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
      </head>
      <body id="body">
        <HomeNavbar />
        <div class="row" id="entrycontent">
          <div class="col-lg-12" align="center">
            <div class="row">
              <div class="col-lg-12">
                <form
                  id="ReactionInputs"
                  style={{
                    display: "block",
                    marginRight: 150,
                    marginLeft: 150,
                  }}
                >
                  <div id="fullForm">
                    <SuggestionInfo />
                    <PartialOrFullSuggestion
                      fullFormUnclicked={fullFormUnclicked}
                      pubFormUnclicked={pubFormUnclicked}
                      unblurFullForm={unblurFullForm}
                      unblurPubForm={unblurPubForm}
                    />
                    <SubmitPartialOrFullForm
                      handleSuggestFull={handleSuggestFull}
                      handleSuggestPartial={handleSuggestPartial}
                      form={form}
                      validChebiCosubstrate={validChebiCosubstrate}
                      validChebiReactant={validChebiReactant}
                      validChebiProduct={validChebiProduct}
                    />
                  </div>
                  <div style={{ position: "relative" }}>
                    <div align="left" id="PubForm" style={{ zIndex: 3 }}>
                      <h5>
                        <FontAwesomeIcon icon={faAsterisk} color="red" />{" "}
                        Publication:
                      </h5>
                      <TextInputOptional
                        label={"Publication Link:"}
                        inputId={"EnzymePublicationLink"}
                        name={"link"}
                        updateForm={updateForm}
                        value={form.link}
                      />
                      <TextInputOptional
                        label={"Pub Med ID:"}
                        inputId={"EnzymePMID"}
                        name={"pubmedId"}
                        updateForm={updateForm}
                        value={form.pubmedId}
                      />
                      <TextInputOptional
                        label={"Publication DOI:"}
                        inputId={"EnzymePublicationDOI"}
                        name={"doi"}
                        updateForm={updateForm}
                        value={form.doi}
                      />
                      <label for="generalNote">Note:</label>
                      <textarea
                        id="generalNote"
                        name="note"
                        maxLength={200}
                        onChange={(event) => {
                          updateForm(event);
                          setNoteLength(event.target.value.split("").length);
                        }}
                        value={form.note}
                      />
                      <p align="right" style={{ fontSize: 12.5 }}>
                        {noteLength}/200
                      </p>
                    </div>
                    <div
                      id="overlapPubForm"
                      style={{
                        position: "absolute",
                        zIndex: 3,
                        display: "block",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                  <div style={{ position: "relative" }}>
                    <div id="FullForm">
                      <h5 align="left">Enzyme:</h5>
                      <div id="enzymeInputs" align="left">
                        <TextInputOptional
                          label={"Name:"}
                          inputId={"EnzymeName"}
                          name={"enzymeName"}
                          updateForm={updateForm}
                          value={form.enzymeName}
                        />
                        <TextAreaMandatory
                          label={"Aminoacid sequence:"}
                          inputId={"EnzymeAASequence"}
                          name={"AASequence"}
                          updateForm={updateForm}
                          value={form.AASequence}
                        />
                        
                        <label for="KingdomRadioInputs">Kingdom:</label>
                        <div
                          id="KingdomRadioInputs"
                          style={{ display: "block", columnCount: 3 }}
                          class="row"
                        >
                          <KingdomFilters updateForm={updateForm} />
                        </div>
                        <TextInputMandatory
                          label={"Species:"}
                          inputId={"EnzymeSpecies"}
                          name={"species"}
                          updateForm={updateForm}
                          value={form.species}
                        />
                        <label for="ClassRadioInputs">Class:</label>
                        <div
                          id="ClassRadioInputs"
                          style={{ display: "block", columnCount: 3 }}
                          class="row"
                        >
                          <ClassFilters updateForm={updateForm} />
                        </div>
                        <TextInputOptional
                          label={"Uniprot ID:"}
                          inputId={"EnzymeUniprot"}
                          name={"uniprot"}
                          updateForm={updateForm}
                          value={form.uniprot}
                        />
                        <TextInputOptional
                          label={"Genbank ID:"}
                          inputId={"EnzymeGenbank"}
                          name={"genbank"}
                          updateForm={updateForm}
                          value={form.genbank}
                        />
                        

                        <label for="TypeRadioInputs">Type:</label>
                        <div
                          id="TypeRadioInputs"
                          style={{ display: "block", columnCount: 3 }}
                          class="row"
                        >
                          <TypeFilters updateForm={updateForm} />
                        </div>
                      </div>
                      <div id="Reactant" align="left">
                        <h5>Substrate:</h5>
                      </div>
                      <div id="reactantInputs" align="left">
                      <TextInputOptional
                          label={"Name:"}
                          inputId={"ReactantName"}
                          name={"reactName"}
                          updateForm={updateForm}
                          value={form.reactName}
                        />
                        <TextInputOptional
                          label={"ChEBI:"}
                          inputId={"ReactantChebi"}
                          name={"reactChebi"}
                          updateForm={updateForm}
                          value={form.reactChebi}
                        />
                        <TextAreaMandatory
                          label={"SMILES:"}
                          inputId={"ReactantSMILES"}
                          name={"reactSmiles"}
                          updateForm={updateForm}
                          value={form.reactSmiles}
                        />
                      </div>
                      <div id="Reactant" align="left">
                        <h5>Cosubstrate:</h5>
                      </div>
                      <div id="reactantInputs" align="left">
                      <TextInputOptional
                          label={"Name:"}
                          inputId={"ReactantName"}
                          name={"cosubstrateName"}
                          updateForm={updateForm}
                          value={form.cosubstrateName}
                        />
                        <TextInputOptional
                          label={"ChEBI:"}
                          inputId={"ReactantChebi"}
                          name={"cosubstrateChebi"}
                          updateForm={updateForm}
                          value={form.cosubstrateChebi}
                        />
                        <label for="ReactantSMILES">SMILES:</label>
                        <textarea
                          id="ReactantSMILES"
                          name="cosubstrateSmiles"
                          onChange={updateForm}
                          value={form.cosubstrateSmiles}
                        />
                      </div>
                      <div id="Product" align="left">
                        <h5>Product:</h5>
                      </div>
                      <div id="productInputs" align="left">
                      <TextInputOptional
                          label={"Name:"}
                          inputId={"ProductName"}
                          name={"prodName"}
                          updateForm={updateForm}
                          value={form.prodName}
                        />
                        <TextInputOptional
                          label={"ChEBI:"}
                          inputId={"ProductChebi"}
                          name={"prodChebi"}
                          updateForm={updateForm}
                          value={form.prodChebi}
                        />
                        <TextAreaMandatory
                          label={"SMILES:"}
                          inputId={"ProductSMILES"}
                          name={"prodSmiles"}
                          updateForm={updateForm}
                          value={form.prodSmiles}
                        />
                      </div>
                    </div>
                    <div
                      id="overlapFullForm"
                      style={{
                        position: "absolute",
                        zIndex: 3,
                        display: "block",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    ></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div id="entryfooter">
          <Footer />
        </div>
      </body>
      <div id="overlap" style={{ display: "none" }}>
        <div id="submitConfirmation" align="left" style={{ display: "none" }}>
          <ul>
            <li>
              <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} /> Your
              suggestion has been successfully submitted and is waiting for a
              review.
            </li>
            <li>
              <button id="ok" onClick={submitOK}>
                OK
              </button>
            </li>
          </ul>
        </div>
        <div id="addMechanism" align="left" style={{ display: "none" }}>
          <ul>
            <li>Do you want to suggest a mechanism as well?</li>
            <li>
              <button id="yes" onClick={yesButton}>
                Yes
              </button>
              <button id="no" onClick={() => noButton(setForm)}>
                No
              </button>
            </li>
          </ul>
          <div id="MechanismInput" style={{ display: "none" }}>
            <ul>
              <li>
                <label for="mechanisminput">
                  <>Suggest a mechanism: </>
                </label>
                <input
                  placeholder="Publication reference"
                  id="mechanisminput"
                  value={mechanismReference}
                  style={{padding:5,outline:"none"}}
                  onChange={(event) => {
                    setMechanismReference(event.target.value);
                  }}
                />
              </li>
              <li>
                <br/>
                <button id="confirmButton" onClick={submitMechanism} style={{borderRadius:5}}>
                  Submit
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </html>
  );
}

export default SuggestReaction;
