import { faBook } from "@fortawesome/free-solid-svg-icons/faBook";
import ExternalLink from "./externalLink.js";

function ReactionLink({ table, row }) {
  return (
    <div
      style={{
        position: "relative",
        maxWidth: "fit-content",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      Reaction: <br />
      <ExternalLink
        externalLink={`${table[row].reaction_link}`}
        icon={faBook}
        idDiv={`goToReactionPublication${row}`}
      >
        See publication
      </ExternalLink>
    </div>
  );
}

export default ReactionLink;
