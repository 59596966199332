import TypeClass from "../Components/ReactionTable/typeClass.js";
import UniprotLink from "../Components/ReactionTable/uniprotLink.js";
import GenbankLink from "../Components/ReactionTable/genbankLink.js";
import CopySequence from "../Components/ReactionTable/copySequence.js";
import KingdomIcon from "../Components/ReactionTable/kingdomIcon.js";
import ExternalLink from "../Components/ReactionTable/externalLink.js";
import { faBook, faCheck } from "@fortawesome/free-solid-svg-icons";
import CosubstrateRow from "../Components/ReactionTable/cosubstrateRow.js";
import SubstrateRow from "../Components/ReactionTable/substrateRow.js";
import ProductRow from "../Components/ReactionTable/productRow.js";
import DisplayOnHover from "../Components/Suggestions/displayOnHover.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReviewRow from "../Components/Suggestions/reviewRow.js";

function renderAllFullSuggestion(
  table,
  suggestion,
  review
) {
  let reactionBody = [];

  for (let row = 0; row < table.length; row++) {
    reactionBody.push([
      table[row].enzyme_name?.split("_")[0] === "missing" ||
      !table[row].enzyme_name ? (
        <>
          <TypeClass table={table} row={row} suggestion={suggestion} />
          <UniprotLink table={table} row={row} suggestion={suggestion} />
          <GenbankLink table={table} row={row} suggestion={suggestion} />
          <CopySequence table={table} row={row} suggestion={suggestion} />
          <KingdomIcon table={table} row={row} suggestion={suggestion} />
        </>
      ) : (
        <>
          <TypeClass table={table} row={row} suggestion={suggestion} />
          <button
            id="enzNameQuickSearch"
            style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
            disabled={suggestion == undefined ? false : true}
          >
            {table[row].enzyme_name}
          </button>
          <br />
          <UniprotLink table={table} row={row} suggestion={suggestion} />
          <GenbankLink table={table} row={row} suggestion={suggestion} />
          <CopySequence table={table} row={row} suggestion={suggestion} />
          <KingdomIcon table={table} row={row} suggestion={suggestion} />
        </>
      ),
      table[row].cosubstrate_smiles ? (
        <CosubstrateRow table={table} row={row} suggestion={suggestion} />
      ) : table[row].substrate_image ? (
        <>
          <img
            src={table[row].substrate_image}
            alt={table[row].substrate_smiles}
            className="responsive"
          />
          <br />
          <SubstrateRow table={table} row={row} suggestion={suggestion} />
        </>
      ) : (
        <>
          {table[row].substrate_smiles}
          <SubstrateRow table={table} row={row} suggestion={suggestion} />
        </>
      ),
      table[row].product_image ? (
        <>
          <img src={table[row].product_image} className="responsive" />
          <br />
          <ProductRow table={table} row={row} suggestion={suggestion} />
        </>
      ) : (
        <>
          {table[row].product_smiles}
          <ProductRow table={table} row={row} suggestion={suggestion} />
        </>
      ),

      <>
        {table[row].re_pair ? (
          <></>
        ) : (
          <div id="reactionSuggestionRow">
            Reaction:{" "}
            <ExternalLink
              idLink={`reactionPublication${row}`}
              idDiv={`goToReactionPublication${row}`}
              externalLink={table[row].publication_link}
              icon={faBook}
            >
              See suggested publication
            </ExternalLink>
          </div>
        )}

        <div id="reactionSuggestionRow">
          {table[row].mechanism_link ? (
            <>
              Mechanism:
              <nobr style={{ padding: 0 }}>
                <ExternalLink
                  idLink={`mechanismPublication${row}`}
                  idDiv={`goToMechanismPublication${row}`}
                  externalLink={table[row].mechanism_link}
                  icon={faBook}
                >
                  See suggested publication
                </ExternalLink>
              </nobr>
            </>
          ) : (
            <></>
          )}
        </div>

        {table[row].note ? (
          <div style={{ position: "relative" }}>
            Note:{" "}
            <DisplayOnHover
              row={row}
              tableOnlyPublication={table}
              idDiv={`displayNote${row}`}
            />
          </div>
        ) : (
          <></>
        )}
        {table[row].review === 0 ? (
          <button
            onClick={()=>review(table,row)}
            style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
          >
            Review now!
          </button>
        ) : table[row].review === 1 ? (
          <>
            <FontAwesomeIcon icon={faCheck} color="green" /> Reviewed!
          </>
        ) : (
          <ReviewRow row={row} tableOnlyPublication={table} table={table} />
        )}
      </>,
    ]);
  }
  return reactionBody;
}

export default renderAllFullSuggestion;
