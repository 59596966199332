import React, { Component } from "react";
import "./suggestionTable.css"
import "./reactionTable.css";
import { faCopy, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const user = [5150, 1984, 2001];

async function showComment(row, col, rowCount) {
  if (
    document.getElementById(`suggestDivComm${row}${col}`).style.display ===
    "none"
  ) {
    document.getElementById(`suggestDivComm${row}${col}`).style.display =
      "block";
    document.getElementById(`suggestInputComm${row}${col}`).value = "";
    for (let r = 0; r < rowCount; r++) {
      document.getElementById(`enzymeGenbankComment${r}`).checked = false;
      document.getElementById(`enzymeUniprotComment${r}`).checked = false;
      document.getElementById(`enzymeKingdomComment${r}`).checked = false;
      document.getElementById(`enzymeClassComment${r}`).checked = false;
      document.getElementById(`enzymeTypeComment${r}`).checked = false;
      document.getElementById(`enzymeSpeciesComment${r}`).checked = false;
      document.getElementById(`enzymeAASequenceComment${r}`).checked = false;
      document.getElementById(`enzymeNameComment${r}`).checked = false;
      document.getElementById(`substrateNameComment${r}`).checked = false;
      document.getElementById(`substrateChebiComment${r}`).checked = false;
      document.getElementById(`substrateSmilesComment${r}`).checked = false;
      document.getElementById(`substrateImageComment${r}`).checked = false;
      document.getElementById(`productSmilesComment${r}`).checked = false;
      document.getElementById(`productImageComment${r}`).checked = false;
      document.getElementById(`productChebiComment${r}`).checked = false;
      document.getElementById(`productNameComment${r}`).checked = false;
      document.getElementById(`nobrEnzyme${r}`).style.display = "none";
      document.getElementById(`nobrSubstrate${r}`).style.display = "none";
      document.getElementById(`nobrProduct${r}`).style.display = "none";

      for (let c = 0; c < 3; c++) {
        if (r != row || c != col) {
          if (document.getElementById(`suggestDivComm${r}${c}`)) {
            document.getElementById(`suggestDivComm${r}${c}`).style.display =
              "none";
            document.getElementById(`suggestInputComm${r}${c}`).value = "";
          }
        }
      }
    }
  } else {
    document.getElementById(`suggestDivComm${row}${col}`).style.display =
      "none";
    document.getElementById(`suggestInputComm${row}${col}`).value = "";
    for (let r = 0; r < rowCount; r++) {
      for (let c = 0; c < 3; c++) {
        if (r != row || c != col) {
          if (document.getElementById(`suggestDivComm${r}${c}`)) {
            document.getElementById(`suggestDivComm${r}${c}`).style.display =
              "none";
            document.getElementById(`suggestInputComm${r}${c}`).value = "";
          }
        }
      }
    }
  }
}

class Table extends Component {
  render() {
    let body = this.props.body;
    let display = this.props.display;
    let seeAllReactions = this.props.seeAllReactions;


    return (
      <table id="suggestionTable" align="center" style={{ breakInside: "avoid-column" }}>
        {body.map((rowContent, rowID) => (
          <tbody >

            {rowContent.length === 1 ? (
              <>
               
                {rowID == 0 ? (
              <tr id="headerRowSuggestion">
                {["Suggestion Details"].map(
                  (head, headID) => (
                    <th
                      id={`headerColSuggestion${headID}`}
                      style={{ position: "relative" }}
                    >
                      {head}
                    </th>
                  )
                )}
              </tr>
              
            ) : (
              <></>
            )}

            <TableRow
              rowContent={rowContent}
              id={`TR${rowID}`}
              seeAllReactions={seeAllReactions}
            />
              </>
          ) : (<>
              {rowID == 0 ? (
              <tr id="headerRowSuggestion">
                {["Enzyme", "Substrate", "Product", "Suggestion Details"].map(
                  (head, headID) => (
                    <th
                      id={`headerColSuggestion${headID}`}
                      style={{ position: "relative" }}
                    >
                      {head}
                      {headID === 3 ? (
                        <button
                          id="closeView"
                          style={{
                            border: "none",
                            display: display,
                            backgroundColor: "rgb(235, 235, 235)",
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                          }}
                          onClick={() => {
                            document.getElementById("overlap").style.display =
                              "none";
                            document.getElementById(
                              "reactionDiv"
                            ).style.display = "none";
                            const elements = document.querySelectorAll("#body");
                            elements.forEach((element) => {
                              element.style.filter = "blur(0px)";
                            });
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            color="rgb(150,150,150)"
                            style={{
                              fontWeight: "lighter",
                              margin: 0,
                              padding: 0,
                            }}
                          />
                        </button>
                      ) : (
                        <></>
                      )}
                    </th>
                  )
                )}
              </tr>
            ) : (
              <></>
            )}
            <TableRow
              rowContent={rowContent}
              id={`TR${rowID}`}
              seeAllReactions={seeAllReactions}
            /></>)}

          </tbody>
        ))}
      </table>
    );
  }
}

class TableRow extends Component {
  render() {
    let row = this.props.rowContent;
    let TRid = this.props.id;
    let seeAllReactions = this.props.seeAllReactions;

    return (
      <tr
        id={`${TRid}`}
        className="reactionContent"
        style={{
          boxShadow:
            "0 6px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2)",
        }}
      >
        {row.map((val, colID) => (
          <td
            key={colID}
            id={seeAllReactions ? "dataColTwoColumns" : "dataCol"}
            style={{ position: "relative" }}
          >
            {val}
          </td>
        ))}
      </tr>
    );
  }
}

export default Table;
