import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";

function SeeMechanismCheckbox({handleToggleMechanism,mechanismUnclicked}) {
  return (
    <button
      style={{
        marginTop: "10px",
        color: "black",
        backgroundColor: "white",
        border: "none",
      }}
      onClick={handleToggleMechanism}
    >
      {mechanismUnclicked == false ? (
        <>
          <FontAwesomeIcon icon={faCheck} color="green" /> Search mechanisms
        </>
      ) : (
        <>Search mechanisms</>
      )}
    </button>
  );
}

export default SeeMechanismCheckbox;
