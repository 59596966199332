import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

function DisplayOnHover({tableOnlyPublication,row,idDiv}) {

  function displayOnOver() {
    document.getElementById(idDiv).style.display = "block";
  }

  function displayOnLeave() {
    document.getElementById(idDiv).style.display = "none";
  }

  return (
    <>
      <button
        onMouseOver={displayOnOver}
        onMouseLeave={displayOnLeave}
        style={{
          border: "none",
          backgroundColor: "rgb(245,245,245)",
          padding: 0,
          margin: "0 auto",
          cursor: "default",
        }}
      >
        <FontAwesomeIcon icon={faEye} color="black" />
      </button>
      <div id={idDiv} className="displayNote">
        {tableOnlyPublication[row].note}
      </div>
    </>
  );
}

export default DisplayOnHover
