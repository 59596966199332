import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

function SuggestionDiv({ row, handleSuggest, placeholder,specification,table }) {
  const note = ""

  return (
    <div
      id={`suggestDiv${specification}${row}`}
      className="suggestDiv"
      style={{
        display: "none",
      }}
    >
      <nobr>
        <input
          id={`suggestInput${specification}${row}`}
          className="suggestInput"
          placeholder={placeholder}
        />
        <button
          id={`confirmButton${specification}${row}`}
          className="confirmButton"
          onClick={() => handleSuggest(row,table,note,specification,false)}
        >
          <div
            id={`innerTextSubmitted${specification}${row}`}
            className="innerTextSubmitted"
            style={{ display: "none" }}
          >
            <FontAwesomeIcon icon={faCheck} color="green" /> Submitted
          </div>{" "}
          <div
            id={`innerTextSubmit${specification}${row}`}
            className="innerTextSubmit"
            style={{ display: "block" }}
          >
            Submit
          </div>
        </button>
      </nobr>
    </div>
  );
}

export default SuggestionDiv;
