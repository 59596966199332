function QuickSearchInput({
  setQuickSearchValue,
  setFirstSearch,
  quickSearchValue
}) {
  return (
    <input
      id="quicksearch"
      placeholder="Search"
      style={{
        border: "none",
        backgroundColor: "rgb(245,245,245)",
        outline: "none",
      }}
      
      onChange={(event) => {
        setQuickSearchValue(event.target.value);
        setFirstSearch(true);
      }}
      onFocus={() => {
        document.getElementById("quicksearch").style.boxShadow =
          "0 6px 20px 0 rgba(255, 255, 255, 0.25), 0 6px 20px 0 rgba(255, 255, 255, 0.25)";
      }}
      onBlur={() => {
        document.getElementById("quicksearch").style.boxShadow =
          "0 0px 0px 0 rgba(255, 255, 255, 0.0), 0 0px 0px 0 rgba(255, 255, 255, 0.0)";
      }}
      value={quickSearchValue}
    />
  );
}

export default QuickSearchInput;
