import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GiSuperMushroom, GiCoral, GiAlgae} from "react-icons/gi";
import { LuBird } from "react-icons/lu";

function Icon({icon,color,alt}) {
    const jsonString = JSON.stringify(icon)
    if (jsonString?.startsWith(`{"prefix`)) {
        return <FontAwesomeIcon icon={icon} color={color}/>
    } else if (jsonString?.startsWith(`"/static`)) {
        return <img src={icon} alt={alt} style={{width: 20, height: 20, backgroundColor: "rgb(245,245,245)"}}/>
    } else {
        if (icon === "GiSuperMushroom") return <GiSuperMushroom color={color}/>
        if (icon === "GiCoral") return <GiCoral color={color}/>
        if (icon === "GiAlgae") return <GiAlgae color={color}/>
        if (icon === "LuBird") return <LuBird color={color}/>
    }
}

export default Icon