export function seeFullForm(
  event,
  fullFormClicked,
  setFullFormClicked,
  setFullFormUnclicked,
  pubFormClicked,
  setPubFormClicked,
  setPubFormUnclicked
) {
  event.preventDefault();
  if (!fullFormClicked) {
    document.getElementById("suggestdetailbutton").style.backgroundColor =
      "gold";
    document.getElementById("overlapFullForm").style.display = "none";
    document.getElementById("overlapPubForm").style.display = "none";
    document.getElementById("confirmButtonFull").style.display = "block";
    const elementsPub = document.querySelectorAll("#PubForm > *");
    elementsPub.forEach((element) => {
      element.style.filter = "unset";
    });
    const elementsFull = document.querySelectorAll("#FullForm > *");
    elementsFull.forEach((element) => {
      element.style.filter = "unset";
    });
    setFullFormClicked(true);

    if (pubFormClicked) {
      document.getElementById("suggestpubbutton").style.backgroundColor =
        "rgb(245,245,245)";
      document.getElementById("confirmButtonPub").style.display = "none";
      setPubFormClicked(false);
      setPubFormUnclicked((current) => !current);
    }
  } else {
    document.getElementById("suggestdetailbutton").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("overlapPubForm").style.display = "block";
    document.getElementById("overlapFullForm").style.display = "block";
    document.getElementById("confirmButtonFull").style.display = "none";
    const elements = document.querySelectorAll("#PubForm > *");
    elements.forEach((element) => {
      element.style.filter = "blur(3px)";
    });
    const elementsFull = document.querySelectorAll("#FullForm > *");
    elementsFull.forEach((element) => {
      element.style.filter = "blur(3px)";
    });
    setFullFormClicked(false);
    setFullFormUnclicked((current) => !current);
  }
}

export function seePubForm(
  event,
  fullFormClicked,
  setFullFormClicked,
  setFullFormUnclicked,
  pubFormClicked,
  setPubFormClicked,
  setPubFormUnclicked
) {
  event.preventDefault();
  if (!pubFormClicked) {
    document.getElementById("suggestpubbutton").style.backgroundColor = "gold";
    document.getElementById("overlapPubForm").style.display = "none";
    document.getElementById("confirmButtonPub").style.display = "block";
    const elementsPub = document.querySelectorAll("#PubForm > *");
    elementsPub.forEach((element) => {
      element.style.filter = "unset";
    });
    setPubFormClicked(true);

    if (fullFormClicked) {
      document.getElementById("suggestdetailbutton").style.backgroundColor =
        "rgb(245,245,245)";
      document.getElementById("overlapFullForm").style.display = "block";
      document.getElementById("confirmButtonFull").style.display = "none";
      const elementsFull = document.querySelectorAll("#FullForm > *");
      elementsFull.forEach((element) => {
        element.style.filter = "blur(3px)";
      });
      setFullFormClicked(false);
      setFullFormUnclicked((current) => !current);
    }
  } else {
    document.getElementById("suggestpubbutton").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("overlapPubForm").style.display = "block";
    document.getElementById("confirmButtonPub").style.display = "none";
    const elements = document.querySelectorAll("#PubForm > *");
    elements.forEach((element) => {
      element.style.filter = "blur(3px)";
    });
    setPubFormClicked(false);
    setPubFormUnclicked((current) => !current);
  }
}


export function initialBlur() {
    const elementsFull = document.querySelectorAll("#FullForm > *");
    elementsFull.forEach((element) => {
      element.style.filter = "blur(3px)";
    });
    const elementsPub = document.querySelectorAll("#PubForm > *");
    elementsPub.forEach((element) => {
      element.style.filter = "blur(3px)";
    });
}
