import HomeNavbar from "../HomeNavbar.js";
import Footer from "../Components/Footer.js";
import { useEffect } from "react";
import SuggestionTable from "../suggestionTable.js";
import { useState } from "react";
import "./MySuggestions.css";
import { showSuggest } from "../functions/showInputFunctions.js";
import { showSuggestNote } from "../functions/showInputFunctions.js";
import useSubmitSuggestedAdditional from "../hooks/useSubmitSuggestedAdditional.js";
import useSuggestedReactionOrMechanism from "../hooks/useSuggestedReactionOrMechanism.js";
import renderPublicationSuggestion from "../api/renderPublicationSuggestion.js";
import SmallLoading from "../Components/smallLoading.js";
import renderFullSuggestion from "../api/renderFullSuggestion.js";
import NoSuggestionMessage from "../Components/Suggestions/noSuggestionMessage.js";

function MySuggestions() {
  const [noteLength, setNoteLength] = useState(0);
  const [columnBody, setColumnBody] = useState({
    first: [],
    second: [],
    third: [],
    fourth: [],
  });
  const [deletePermissionGiven] = useState(false);
  const [reactionBody, setReactionBody] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [suggestUnclicked, setSuggestUnlicked] = useState(false);
  const [table, setTable] = useState([]);

  const submitSuggestAdditional = useSubmitSuggestedAdditional();
  const suggestedReactionOrMechanism = useSuggestedReactionOrMechanism();
  const suggestion = true;

  function seeSuggestionInput(row, specification) {
    showSuggest(row, table.length, setSuggestUnlicked, specification);
  }

  function seeSuggestNote(row, specification) {
    showSuggestNote(
      row,
      table.length,
      setNoteLength,
      setSuggestUnlicked,
      specification
    );
  }

  async function onlyPublicationSuggestion() {
    const tableOnlyPublication = await suggestedReactionOrMechanism(true);

    const onlyPublicationSuggestionBody = await renderPublicationSuggestion(
      tableOnlyPublication,
      seeSuggestionInput,
      submitSuggestAdditional,
      seeSuggestNote,
      table
    );
    setIsLoading(false);
    let firstColumn = [];
    let secondColumn = [];
    let thirdColumn = [];
    let fourthColumn = [];
    for (let i = 0; i < onlyPublicationSuggestionBody.length; i++) {
      firstColumn.push(
        onlyPublicationSuggestionBody[0 + i * 4]
          ? onlyPublicationSuggestionBody[0 + i * 4]
          : []
      );
      secondColumn.push(
        onlyPublicationSuggestionBody[1 + i * 4]
          ? onlyPublicationSuggestionBody[1 + i * 4]
          : []
      );
      thirdColumn.push(
        onlyPublicationSuggestionBody[2 + i * 4]
          ? onlyPublicationSuggestionBody[2 + i * 4]
          : []
      );
      fourthColumn.push(
        onlyPublicationSuggestionBody[3 + i * 4]
          ? onlyPublicationSuggestionBody[3 + i * 4]
          : []
      );
    }
    setColumnBody(() => ({
      first: firstColumn,
      second: secondColumn,
      third: thirdColumn,
      fourth: fourthColumn,
    }));
  }

  async function displaySuggestions() {
    const table = await suggestedReactionOrMechanism(false);
    setTable(table);
    setIsLoading(false);

    const reactionBody = await renderFullSuggestion(
      table,
      suggestion,
      seeSuggestionInput,
      seeSuggestNote,
      submitSuggestAdditional
    );
    setReactionBody(reactionBody);
  }

  useEffect(() => {
    displaySuggestions();
    onlyPublicationSuggestion();
  }, [table.length, noteLength, deletePermissionGiven]);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
      </head>
      <body id="body">
        <HomeNavbar suggestionCount={table.length} />

        <div id="mySuggestions">
          {isLoading ? (
            <>
              <SmallLoading />
            </>
          ) : reactionBody.length > 0 && columnBody.first.length > 0 ? (
            <>
              <h2>Full suggestions of reactions and their mechanisms:</h2>
              <SuggestionTable body={reactionBody} display={"none"} />
              <div style={{ marginTop: 50 }}>
                <h2>Suggested publication references:</h2>
              </div>
              <div id="publicationFourColumns">
                <SuggestionTable body={columnBody.first} display={"none"} />
                <SuggestionTable body={columnBody.second} display={"none"} />
                <SuggestionTable body={columnBody.third} display={"none"} />
                <SuggestionTable body={columnBody.fourth} display={"none"} />
              </div>
            </>
          ) : reactionBody.length > 0 && columnBody.first.length === 0 ? (
            <>
              <h2>Full suggestions of reactions and their mechanisms:</h2>
              <SuggestionTable body={reactionBody} display={"none"} />
            </>
          ) : reactionBody.length === 0 && columnBody.first.length > 0 ? (
            <>
              <div style={{ marginTop: 50 }}>
                <h2>Suggested publication references:</h2>
              </div>
              <div id="publicationFourColumns">
                <SuggestionTable body={columnBody.first} display={"none"} />
                <SuggestionTable body={columnBody.second} display={"none"} />
                <SuggestionTable body={columnBody.third} display={"none"} />
                <SuggestionTable body={columnBody.fourth} display={"none"} />
              </div>
            </>
          ) : (
            <NoSuggestionMessage />
          )}
        </div>
        <Footer />
      </body>
    </html>
  );
}

export default MySuggestions;
