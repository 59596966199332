import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faAngleDoubleUp,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

function ResultBar({
  searchSubmit,
  isLoading,
  dataCount,
  filtersVisible,
  hideFilters,
}) {
  return (
    <div
      id="SearchSuccess"
      align="left"
      style={{ marginTop: 10, marginBottom: 10 }}
    >
      {!searchSubmit ? (
        isLoading ? (
          <>
            <Spin
              indicator={
                <LoadingOutlined style={{ color: "rgb(23, 55, 151)" }} spin />
              }
            />{" "}
            Searching for results, please wait...
          </>
        ) : (
          ""
        )
      ) : dataCount == 0 ? (
        <>
          <FontAwesomeIcon icon={faTimes} id="cross" /> No results
        </>
      ) : (
        <>
          {filtersVisible ? (
            <>
              <ul style={{ position: "relative" }}>
                <li>
                  <FontAwesomeIcon icon={faCheck} id="tick" />{" "}
                  {dataCount + " results found"}
                </li>
                <li>
                  <button type="button" id="DoubleDown" onClick={hideFilters}>
                    <FontAwesomeIcon icon={faAngleDoubleUp} id="arrow" />
                  </button>
                  <p id="scrollDownNote">Hide filters</p>
                </li>
              </ul>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
}

export default ResultBar;
