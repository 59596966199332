import { useSearchParams } from "react-router-dom";

function useSearchParamsState(searchParamName, defaultValue) {
  const [searchParams, setSearchParams] = useSearchParams();

  let searchParamsState;
  let acquiredSearchParam;

  if (typeof defaultValue == "object") {
    let keys = Object.keys(defaultValue).map((key) => key);
    let valuesFromUrl = keys.map((element) => searchParams.get(element));
    searchParamsState = { page: searchParams.get("page") ?? 1};
    keys.forEach((key, index) => {
      searchParamsState[key] = valuesFromUrl[index] ?? "";
    });
  } else {
    acquiredSearchParam = searchParams.get(searchParamName);
    acquiredSearchParam = acquiredSearchParam ?? defaultValue;

    searchParamsState =
      isNaN(acquiredSearchParam) || acquiredSearchParam === ""
        ? acquiredSearchParam === "true" || acquiredSearchParam === "false"
          ? (acquiredSearchParam = acquiredSearchParam === "true")
          : acquiredSearchParam
        : Number(acquiredSearchParam) ?? defaultValue;
  }

  function setSearchParamsState(newState) {
    let newValue;
    let newName;

    if (typeof newState === "function") {
      const next = newState(searchParamsState)

      setSearchParams(next);
    } else {
      newValue = newState;
      newName = searchParamName;

      const next = Object.assign(
        {},
        [...searchParams.entries()].reduce(
          (o, [key, value]) => ({ ...o, [key]: value }),
          {}
        ),
        { [newName]: newValue }
      );
      setSearchParams(next);
    }
  }

  return [searchParamsState, setSearchParamsState];
}

export default useSearchParamsState;
