function InteractiveImage({
  src,
  alt,
  className,
  interactiveSearch,
  interactiveAdvancedSearch,
  showPopups,
  isQuickSearch,
  parameter,
  suggestion,
}) {
  const DISABLED_CONDITION = suggestion == undefined ? false : true;

  function handleClick() {
    return isQuickSearch
      ? interactiveSearch(alt)
      : interactiveAdvancedSearch(showPopups, parameter, alt, false);
  }

  return (
    <button
      style={{
        border: "none",
        backgroundColor: "rgb(245,245,245)",
        color: "black",
      }}
      disabled={DISABLED_CONDITION}
    >
      <img src={src} alt={alt} className={className} onClick={handleClick} />
    </button>
  );
}

export default InteractiveImage;
