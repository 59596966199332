import useAxiosPrivate from "./useAxiosPrivate.js";
import useAuth from "./useAuth.js";

function useSuggestedReactionOrMechanism() {
    const axiosPrivate = useAxiosPrivate()
    const {auth} = useAuth()

    async function suggestedReactionOrMechanism(seeOnlyPublication) {
        const username = auth.username
        try {
          const response = await axiosPrivate.post("/mysuggestions", {
            username: username,
            seeOnlyPublication
          });
          return response.data
        } catch (err) {
            throw err
        }
    }


    return suggestedReactionOrMechanism


}

export default useSuggestedReactionOrMechanism