function MoleculeNameButton({id,children,disabled,onClick}) {
    return (
        <li
        style={{
          wordWrap: "normal",
          maxWidth: "20ch",
          listStyle: "none",
          padding: "none",
          margin: "0 auto",
        }}
      >
        <button
          id={id}
          disabled={disabled}
          style={{
            border: "none",
            backgroundColor: "rgb(245,245,245)",
            padding: 0,
          }}
          onClick={onClick}
        >
          {children}
        </button>
      </li>
    )
}

export default MoleculeNameButton