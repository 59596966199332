import Footer from "../Components/Footer.js";
import { useEffect, useState } from "react";
import useDidUpdateEffect from "../hooks/useDidUpdateEffect.js";
import { useLocation,useNavigate } from "react-router-dom";
import "./newMechanism.css";
import {
  faPlus,
  faMinus,
  faCheck,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import useAuth from "../hooks/useAuth.js";
import HomeNavbar from "../HomeNavbar.js";
import encoding from "encoding";
import MechanismStep from "../Components/Entries/mechanismStep.js";

function NewMechanism() {
  const location = useLocation();
  const navigate = useNavigate()
  const { state } = location;
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const [rejectNote,setRejectNote] = useState("")
  const [noteLength, setNoteLength] = useState(0);
  const [validChebi, setValidChebi] = useState(true);
  const [inputCount, setInputCount] = useState(2);
  const [increaseSignaller, setIncreaseSignaller] = useState(false);
  const [decreaseSignaller, setDecreaseSignaller] = useState(false);
  const [form, setForm] = useState({
    name: {
      1: "",
      2: state?.product_name,
    },
    chebi: {
      1: "",
      2: state?.product_chebi,
    },
    substrateIntermediate: {
      0: state?.substrate_smiles,
      1: "",
    },
    productIntermediate: {
      1: "",
      2: state?.product_smiles,
    },
    type: {
      1: "",
      2: "",
    },
    evidence: "",
    link: "",
    doi: "",
    pmid: "",
  });

  const CHEBI_REGEX = /^[0-9]{0,50}$/;
  const editor = [5150,1984]


  async function rejectSuggestion(event) {
    event.preventDefault();

    try {
      await axiosPrivate.post("/rejectsuggestion", {
        suggestion_id: state.suggestion_id,
      });
    } catch (err) {
      console.error(err);
    } finally {
      const elements = document.querySelectorAll("#body > *:not(#overlap)");
      elements.forEach((element) => {
        element.style.filter = "blur(3px)";
      });
      document.getElementById("submitConfirmation").style.display = "none";
      document.getElementById("duplication").style.display = "none";
      document.getElementById("overlap").style.display = "block";
      document.getElementById("rejectNote").style.display = "block";
    }
  }

  async function feedbackYes() {
    document.getElementById("rejectNote").style.display = "none";
    document.getElementById("RejectNote").style.display = "block";
  }

  async function feedbackNo() {
    document.getElementById("overlap").style.display = "none";
    document.getElementById("rejectNote").style.display = "none";
    navigate("/suggestions");
  }

  async function submitNote() {
    try {
      await axiosPrivate.post("/rejectsuggestion", {
        suggestion_id: state.suggestion_id,
        rejectNote: rejectNote,
      });
    } catch (err) {
      throw err;
    } finally {
      navigate("/suggestions");
    }
  }

  useEffect(() => {
    for (const chebi of Object.values(form.chebi)) {
      if (!CHEBI_REGEX.test(chebi)) {
        setValidChebi(false);
        break;
      } else {
        setValidChebi(true);
      }
    }
  }, [form.chebi]);

  function updateSmiles(event) {
    setForm((prev) => ({
      ...prev,
      productIntermediate: {
        ...prev.productIntermediate,
        [event.target.name]: event.target.value,
      },
      substrateIntermediate: {
        ...prev.substrateIntermediate,
        [event.target.name]: event.target.value,
      },
    }));
  }

  function updateName(event) {
    setForm((prev) => ({
      ...prev,
      name: {
        ...prev.name,
        [event.target.name]: event.target.value,
      },
    }));
  }

  function updateChebi(event) {
    setForm((prev) => ({
      ...prev,
      chebi: {
        ...prev.chebi,
        [event.target.name]: event.target.value,
      },
    }));
  }

  function updateType(event) {
    setForm((prev) => ({
      ...prev,
      type: {
        ...prev.type,
        [event.target.name]: event.target.value,
      },
    }));
  }

  function updateOthers(event) {
    setForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  }

  useDidUpdateEffect(() => {
    setForm((prev) => ({
      ...prev,
      productIntermediate: {
        ...prev.productIntermediate,
        [inputCount - 1]: "",
        [inputCount]: state.product_smiles,
      },
      name: {
        ...prev.name,
        [inputCount - 1]: "",
        [inputCount]: state.product_name,
      },
      chebi: {
        ...prev.chebi,
        [inputCount - 1]: "",
        [inputCount]: state.product_chebi,
      },
    }));
  }, [increaseSignaller]);

  useDidUpdateEffect(() => {
    setForm((prev) => {
      const { [inputCount]: toDeleteSubstrate, ...prevSubsIntermediate } =
        prev.substrateIntermediate;
      const { [inputCount + 1]: toDeleteProduct, ...prevProdIntermediate } =
        prev.productIntermediate;
      const { [inputCount + 1]: toDeleteName, ...prevName } = prev.name;
      const { [inputCount + 1]: toDeleteChebi, ...prevChebi } = prev.chebi;
      return {
        ...prev,
        productIntermediate: {
          ...prevProdIntermediate,
          [inputCount]: state.product_smiles,
        },
        substrateIntermediate: {
          ...prevSubsIntermediate,
        },
        name: {
          ...prevName,
          [inputCount]: state.product_name,
        },
        chebi: {
          ...prevChebi,
          [inputCount]: state.product_chebi,
        },
      };
    });
  }, [decreaseSignaller]);

  async function submitOK() {
    document.getElementById("submitConfirmation").style.display = "none";
    document.getElementById("duplication").style.display = "none";
    document.getElementById("overlap").style.display = "none";
    const elements = document.querySelectorAll("#body > *:not(#overlap)");
    elements.forEach((element) => {
      element.style.filter = "unset";
    });
  }

  async function submit(event) {
    event.preventDefault();

    try {
      const username = auth.username;
      const response = await axiosPrivate.post("/mechanismentry", {
        RE_pair: state.RE_pair,
        username,
        suggestion_id: state.suggestion_id,
        form: {
          ...form,
          substrateIntermediate: Object.entries(
            form.substrateIntermediate
          ).reduce(
            (o, [key, value]) => ({
              ...o,
              [key]: encoding.convert(value, "Latin_1"),
            }),
            {}
          ),
          productIntermediate: Object.entries(form.productIntermediate).reduce(
            (o, [key, value]) => ({
              ...o,
              [key]: encoding.convert(value, "Latin_1"),
            }),
            {}
          ),
        },
      });
      if (!response.data.duplication) {
        const elements = document.querySelectorAll("#body > *:not(#overlap)");
        elements.forEach((element) => {
          element.style.filter = "blur(3px)";
        });
        document.getElementById("overlap").style.display = "block";
        document.getElementById("submitConfirmation").style.display = "block";
      } else {
        const elements = document.querySelectorAll("#body > *:not(#overlap)");
        elements.forEach((element) => {
          element.style.filter = "blur(3px)";
        });
        document.getElementById("overlap").style.display = "block";
        document.getElementById("duplication").style.display = "block";
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
      </head>
      <body id="body">
        <HomeNavbar />
        <div
          class="row"
          id="entrycontent"
          style={{
            paddingTop: "165px",
            paddingBottom: "115px",
          }}
        >
          <div class="col-lg-12" align="center">
            <div class="row">
              <div class="col-lg-12">
                <ul id="buttonUl">
                  <li>
                    <button
                      id="addInput"
                      onClick={() => {
                        setInputCount((prev) => prev + 1);
                        setIncreaseSignaller((current) => !current);
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} color="rgb(150,150,150)" />
                    </button>
                  </li>
                  <li>
                    <button
                      id="deleteInput"
                      onClick={() => {
                        if (inputCount > 2) {
                          setInputCount((prev) => prev - 1);
                          setDecreaseSignaller((current) => !current);
                        }
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faMinus}
                        color="rgb(150,150,150)"
                      />
                    </button>
                  </li>
                </ul>
                <form id="MechanismInputs" align="left">
                  <MechanismStep
                    updateSmiles={updateSmiles}
                    updateName={updateName}
                    updateChebi={updateChebi}
                    updateType={updateType}
                    form={form}
                    inputCount={inputCount}
                    number={1}
                  >
                    Step 1:
                  </MechanismStep>

                  <MechanismStep
                    updateSmiles={updateSmiles}
                    updateName={updateName}
                    updateChebi={updateChebi}
                    updateType={updateType}
                    form={form}
                    inputCount={inputCount}
                    number={2}
                  >
                    Step 2:
                  </MechanismStep>
                  {[...Array(inputCount)].map((_, index) => {
                    if (index >= 2) {
                      return (
                        <MechanismStep
                          updateChebi={updateChebi}
                          updateName={updateName}
                          updateSmiles={updateSmiles}
                          updateType={updateType}
                          number={index + 1}
                          inputCount={inputCount}
                          form={form}
                        >
                          Step {index + 1}:
                        </MechanismStep>
                      );
                    }
                  })}
                  <h5 align="left">Publication:</h5>
                  <label for="pubLink">Link:</label>
                  <textarea
                    id="pubLink"
                    value={form.link}
                    name="link"
                    onChange={updateOthers}
                  ></textarea>
                  <label for="pubDoi">DOI:</label>
                  <input
                    id="pubDoi"
                    value={form.doi}
                    name="doi"
                    onChange={updateOthers}
                  ></input>
                  <label for="pubPmid">PMID:</label>
                  <input
                    id="pubPmid"
                    value={form.pmid}
                    name="pmid"
                    onChange={updateOthers}
                  ></input>
                  <h5 align="left">Evidence:</h5>
                  <label for="evidence">Mechanism evidence:</label>
                  <input
                    id="evidence"
                    value={form.evidence}
                    name="evidence"
                    onChange={updateOthers}
                  ></input>

                  <ul
                    style={{
                      listStyle: "none",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <li>
                      <button
                        id="submitButton"
                        onClick={submit}
                        disabled={
                          Object.entries(form.productIntermediate).reduce(
                            (acc, [key, value]) => {
                              if (value === "") {
                                return true;
                              }
                            },
                            false
                          ) ||
                          Object.entries(form.substrateIntermediate).reduce(
                            (acc, [key, value]) => {
                              if (value === "") {
                                return true;
                              }
                            },
                            false
                          ) ||
                          !validChebi ||
                          !form.publication
                        }
                        style={
                          Object.entries(form.productIntermediate).reduce(
                            (acc, [key, value]) => {
                              if (value === "") {
                                return true;
                              }
                            },
                            false
                          ) ||
                          Object.entries(form.substrateIntermediate).reduce(
                            (acc, [key, value]) => {
                              if (value === "") {
                                return true;
                              }
                            },
                            false
                          ) ||
                          !validChebi ||
                          !form.publication
                            ? { opacity: "0.5" }
                            : { opacity: "1" }
                        }
                      >
                        Submit
                      </button>
                    </li>
                    <li
                      style={
                        editor.includes(auth?.roles) &&
                        state?.from === "/suggestions"
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <button id="confirmButton" onClick={rejectSuggestion}>
                        Reject
                      </button>
                    </li>
                  </ul>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div id="footer" style={{ display: "none" }}>
          <Footer />
        </div>
        <div id="overlap" style={{ display: "none" }}>
        <div
          id="rejectNote"
          align="left"
          style={{ display: "none", width: "50%", zIndex: 5 }}
        >
          <ul>
            <li>
              <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />{" "}
              Suggestion has been successfully rejected. Would you like to
              provide a feedback?
            </li>
            <li>
              <button id="yes" onClick={feedbackYes}>
                Yes
              </button>
            </li>
            <li>
              <button id="no" onClick={feedbackNo}>
                No
              </button>
            </li>
          </ul>
        </div>

        <div id="RejectNote" style={{ display: "none" }}>
          <ul>
            <li>
              <label for="mechanisminput">
                <>Post a feedback: </>
              </label>
              <textarea
                placeholder="Feedback note"
                id="mechanisminput"
                value={rejectNote}
                maxLength={500}
                onChange={(event) => {
                  setRejectNote(event.target.value);
                  setNoteLength(event.target.value.split("").length);
                }}
              />
              <p style={{ fontSize: 14 }} align="left">
                {noteLength}/500
              </p>
            </li>
            <li>
              <button id="confirmButton" onClick={submitNote}>
                Submit
              </button>
            </li>
          </ul>
        </div>

          <div id="submitConfirmation" align="left">
            <ul>
              <li>
                <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />{" "}
                Your entry has been successfully submitted to the database.
              </li>
              <li>
                <button id="ok" onClick={submitOK}>
                  OK
                </button>
              </li>
            </ul>
          </div>
          <div id="duplication" align="left">
            <ul>
              <li>
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  style={{ color: "red" }}
                />{" "}
                Your entry contained one or more duplicate values.<br></br>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green" }}
                />{" "}
                Duplicates were not inserted and your entry remains valid.
              </li>
              <li>
                <button id="ok" onClick={submitOK}>
                  OK
                </button>
              </li>
            </ul>
          </div>
        </div>
      </body>
    </html>
  );
}

export default NewMechanism;
