import MoleculeNameButton from "./moleculeNameButton.js";
import SmilesChebiRow from "./smilesChebiRow.js";
import CopySmilesButton from "./copySmilesButton.js";
import ExternalLink from "./externalLink.js";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";

function SubstrateRow({
  suggestion,
  table,
  row,
  interactiveSearch,
  isQuickSearch,
  interactiveAdvancedSearch,
  showSubstratePopups
}) {
  const DISABLED_CONDITION = suggestion == undefined ? false : true;


  function copySmiles(id) {
    let smiles = document.getElementById(id).innerText;
    let elem = document.createElement("textarea");
    document.body.appendChild(elem);
    smiles = smiles.trim();
    elem.value = smiles;
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
  }

  function handleClick() {
    return isQuickSearch
      ? interactiveSearch(table[row].substrate_name)
      : interactiveAdvancedSearch(showSubstratePopups,"reactName",table[row].substrate_name,false);
  }

  return (
    <>
      {table[row].substrate_smiles ? (
        <>
          <MoleculeNameButton
            id={"substrateNameQuickSearch"}
            disabled={DISABLED_CONDITION}
            onClick={handleClick}
          >
            {table[row].substrate_name}
          </MoleculeNameButton>

          <SmilesChebiRow>
            <CopySmilesButton table={table} row={row} copySmiles={copySmiles} substrateOrProduct={"substrate"}/>
            <li
              style={{ marginRight: 5, position: "relative" }}
              className="chebiLink"
            >
              {table[row].substrate_chebi ? (
                <>
                  <ExternalLink
                    externalLink={`https://www.ebi.ac.uk/chebi/searchId.do?chebiId=CHEBI:${table[row].substrate_chebi}`}
                    icon={faExternalLink}
                    idDiv={`goToChebi${row}`}
                  >
                    Find in ChEBI: {table[row].substrate_chebi}
                  </ExternalLink>
                </>
              ) : (
                <></>
              )}
            </li>
          </SmilesChebiRow>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default SubstrateRow;
