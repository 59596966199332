import useAxiosPrivate from "./useAxiosPrivate.js";
import useAuth from "./useAuth.js";

function useSubmitSuggestedMechLink() {
    const axiosPrivate = useAxiosPrivate()
    const {auth} = useAuth()

    async function submitSuggest(row,table) {
        const inputValue = document.getElementById(`suggestInputFull${row}`).value;
        document.getElementById(`innerTextSubmittedFull${row}`).style.display = "block";
        document.getElementById(`innerTextSubmitFull${row}`).style.display = "none";
        try {
          await axiosPrivate.post("/mechanismlink", {
            inputValue: inputValue,
            reaction: table[row],
            username: auth?.username
          });
        } catch (err) {
          throw err;
        } finally {
          setTimeout(() => {
            document.getElementById(`suggestDivFull${row}`).style.display = "none";
            document.getElementById(`innerTextSubmittedFull${row}`).style.display =
              "none";
            document.getElementById(`innerTextSubmitFull${row}`).style.display =
              "block";
          }, 1000);
        }
      }

      return submitSuggest
}

export default useSubmitSuggestedMechLink

