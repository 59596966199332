function mechanismDataToCsv(data){
    let res = "Enzyme_uniprot_ids,Enzyme_genbank_ids,Substrate_smiles,Substrate_name,Product_smiles,Product_name,Reaction_type,Evidence,Publication\n"
    let prev_mech_id = data[0].mechanism_id
    let prev_mech_step_id = data[0].mechanism_step_id
    let same_mechanism = []
    same_mechanism.push(data[0])
    data.forEach((element) => {
        if(element.mechanism_id == prev_mech_id && element.mechanism_step_id == prev_mech_step_id){
            same_mechanism.push(element)
        }
        else{
            let enzymes_uniprot = new Set()
            let enzymes_genbank = new Set()
            let substrate_names = new Set()
            let product_names = new Set()
            let substrate_smiles_set = new Set()
            let product_smiles_set = new Set()
            let reaction_type = ""
            let evidence = ""
            let publications = new Set()
            same_mechanism.forEach((step) =>  {
                enzymes_uniprot.add(step.uniprot_id)
                enzymes_genbank.add(step.genbank_id)
                substrate_names.add(step.substrate_name)
                substrate_smiles_set.add(step.substrate_smiles)
                product_names.add(step.product_name)
                product_smiles_set.add(step.product_smiles)
                reaction_type = step.step_type
                evidence = step.mechanism_evidence
                publications.add(step.publication_link)
            })
            enzymes_genbank.delete("")
            enzymes_uniprot.delete("")
            substrate_names.delete("")
            product_names.delete("")
            substrate_smiles_set.delete("")
            product_smiles_set.delete("")
            publications.delete("")
            enzymes_uniprot = Array.from(enzymes_uniprot).join(";")
            enzymes_genbank = Array.from(enzymes_genbank).join(";")
            substrate_names = Array.from(substrate_names).join(";")
            publications = Array.from(publications).join(";")
            let substrate_smiles = Array.from(substrate_smiles_set).join(";")
            product_names = Array.from(product_names).join(";")
            let product_smiles = Array.from(product_smiles_set).join(";")
            let new_line = `"${enzymes_uniprot}","${enzymes_genbank}","${substrate_smiles}","${substrate_names}","${product_smiles}","${product_names}","${reaction_type}","${evidence}","${publications}"\n`
            res = res + new_line
            same_mechanism = []
            same_mechanism.push(element)
            prev_mech_id = element.mechanism_id
            prev_mech_step_id = element.mechanism_step_id
    }
    }); 
    return res
    } 
    
export default mechanismDataToCsv