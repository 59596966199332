export function showInput(inputId, buttonId, state, setState, divId, setUnclicked) {
    inputId.forEach((e) => {
      let showInput = document.getElementById(e);

      if (showInput.style.display === "none") {
        showInput.style.display = "block";
      } else {
        showInput.style.display = "none";
      }
    });

    const button = document.getElementById(buttonId);
    const div = document.getElementById(divId);
    
    if (state === false) {
      button.style.color = "gold";
      div.style.backgroundColor = "rgb(245,245,245)";
      div.style.borderTop = "2px solid lightgrey";
      setState(true);
    } else {
      button.style.color = "black";
      div.style.backgroundColor = "white";
      div.style.borderTop = "none";
      setState(false);
      setUnclicked((current) => !current);
    }
  }

export function showSuggest(row,suggestionCount,setSuggestUnlicked,specification) {
  if (document.getElementById(`suggestDiv${specification}${row}`).style.display === "none") {
    document.getElementById(`suggestDiv${specification}${row}`).style.display = "block";
    document.getElementById(`suggestInput${specification}${row}`).value = "";
    for (let i = 0; i < suggestionCount; i++) {
      if (i != row) {
        if (document.getElementById(`suggestDiv${specification}${i}`)) {
          document.getElementById(`suggestDiv${specification}${i}`).style.display = "none";
          document.getElementById(`suggestInput${specification}${i}`).value = "";
        }
      }
    }
  } else {
    document.getElementById(`suggestDiv${specification}${row}`).style.display = "none";
    document.getElementById(`suggestInput${specification}${row}`).value = "";
    setSuggestUnlicked((current) => !current);
    for (let i = 0; i < suggestionCount; i++) {
      if (i != row) {
        if (document.getElementById(`suggestDiv${specification}${i}`)) {
          document.getElementById(`suggestDiv${specification}${i}`).style.display = "none";
          document.getElementById(`suggestInput${specification}${i}`).value = "";
        }
      }
    }
  }
}

export function showSuggestNote(row,suggestionCount,setNoteLength, setSuggestUnlicked,specification) {
  setNoteLength(0);
  if (
    document.getElementById(`suggestDivNote${specification}${row}`).style.display === "none"
  ) {
    document.getElementById(`suggestDivNote${specification}${row}`).style.display = "block";
    document.getElementById(`suggestInputNote${specification}${row}`).value = "";
    for (let i = 0; i < suggestionCount; i++) {
      if (i != row) {
        if (document.getElementById(`suggestDivNote${specification}${i}`)) {
          document.getElementById(`suggestDivNote${specification}${i}`).style.display =
            "none";
          document.getElementById(`suggestInputNote${specification}${i}`).value = "";
        }
      }
    }
  } else {
    document.getElementById(`suggestDivNote${specification}${row}`).style.display = "none";
    document.getElementById(`suggestInputNote${specification}${row}`).value = "";
    setSuggestUnlicked((current) => !current);
    for (let i = 0; i < suggestionCount; i++) {
      if (i != row) {
        if (document.getElementById(`suggestDivNote${specification}${i}`)) {
          document.getElementById(`suggestDivNote${specification}${i}`).style.display =
            "none";
          document.getElementById(`suggestInputNote${specification}${i}`).value = "";
        }
      }
    }
  }
}