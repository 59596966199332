import CheckboxInput from "./chechboxInput.js";

function KingdomFilters({ updateForm,searchParams }) {
  return (
    <>
      <CheckboxInput
        idLabel={"animaliamammalinput"}
        idInput={"AnimaliaMammalInput"}
        inputText={"Animalia (Mammal)"}
        value={"Animalia (Mammal)"}
        name={"animaliaMammal"}
        updateForm={updateForm}
        searchParams={searchParams}
      />
      <CheckboxInput
        idLabel={"animaliacoralinput"}
        idInput={"AnimaliaCoralInput"}
        inputText={"Animalia (Coral)"}
        value={"Animalia (Coral)"}
        name={"animaliaCoral"}
        updateForm={updateForm}
        searchParams={searchParams}
      />
      <CheckboxInput
        idLabel={"animaliabirdinput"}
        idInput={"AnimaliaBirdInput"}
        inputText={"Animalia (Bird)"}
        value={"Animalia (Bird)"}
        name={"animaliaBird"}
        updateForm={updateForm}
        searchParams={searchParams}
      />
      <CheckboxInput
        idLabel={"animaliamammalhumaninput"}
        idInput={"AnimaliaMammalHumanInput"}
        inputText={"Animalia (Mammal, Human)"}
        value={"Animalia (Mammal, Human)"}
        name={"animaliaMammalHuman"}
        updateForm={updateForm}
        searchParams={searchParams}
      />
      <CheckboxInput
        idLabel={"animaliainsectinput"}
        idInput={"AnimaliaInsectInput"}
        inputText={"Animalia (Insect)"}
        value={"Animalia (Insect)"}
        name={"animaliaInsect"}
        updateForm={updateForm}
        searchParams={searchParams}
      />
      <CheckboxInput
        idLabel={"animaliamarinespongeinput"}
        idInput={"AnimaliaMarineSpongeInput"}
        inputText={"Animalia (Marine Sponge)"}
        value={"Animalia (Marine Sponge)"}
        name={"animaliaMarineSponge"}
        updateForm={updateForm}
        searchParams={searchParams}
      />
      <CheckboxInput
        idLabel={"plantaeinput"}
        idInput={"PlantaeInput"}
        inputText={"Plantae"}
        value={"Plantae"}
        name={"plantae"}
        updateForm={updateForm}
        searchParams={searchParams}
      />
      <CheckboxInput
        idLabel={"fungiinput"}
        idInput={"FungiInput"}
        inputText={"Fungi"}
        value={"Fungi"}
        name={"fungi"}
        updateForm={updateForm}
        searchParams={searchParams}
      />
      <CheckboxInput
        idLabel={"bacteriainput"}
        idInput={"BacteriaInput"}
        inputText={"Bacteria"}
        value={"Bacteria"}
        name={"bacteria"}
        updateForm={updateForm}
        searchParams={searchParams}
      />
      <CheckboxInput
        idLabel={"amoebozoainput"}
        idInput={"AmoebozoaInput"}
        inputText={"Amoebozoa"}
        value={"Amoebozoa"}
        name={"amoebozoa"}
        updateForm={updateForm}
        searchParams={searchParams}
      />
      <CheckboxInput
        idLabel={"archaeainput"}
        idInput={"ArchaeaInput"}
        inputText={"Archaea"}
        value={"Archaea"}
        name={"archaea"}
        updateForm={updateForm}
        searchParams={searchParams}
      />
      <CheckboxInput
        idLabel={"virusesinput"}
        idInput={"VirusesInput"}
        inputText={"Viruses"}
        value={"Viruses"}
        name={"viruses"}
        updateForm={updateForm}
        searchParams={searchParams}
      />
      <CheckboxInput
        idLabel={"cyanobacteriainput"}
        idInput={"CyanobacteriaInput"}
        inputText={"Cyanobacteria"}
        value={"Cyanobacteria"}
        name={"cyanobacteria"}
        updateForm={updateForm}
        searchParams={searchParams}
      />
      <CheckboxInput
        idLabel={"plantaeredalgaeinput"}
        idInput={"PlantaeRedAlgaeInput"}
        inputText={"Plantae (Red algae)"}
        name={"plantaeRedAlgae"}
        value={"Plantae (Red algae)"}
        updateForm={updateForm}
        searchParams={searchParams}
      />
    </>
  );
}

export default KingdomFilters;
