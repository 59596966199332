import { Buffer } from "buffer";

async function searchSuggestMechanism({
  form,
  pageCount,
  buttonCount,
  setIsLoading,
  setSearchSubmit,
  setDataCount,
  axios
}) {

  const terpType = (({di,mono,tetra,tri,sesq,sesquar,sester,prenyl,hemi,}) => 
    ({ di, mono, tetra, tri, sesq, sesquar, sester, prenyl, hemi }))(form);

  const kingdom = (({animaliaMammal,animaliaCoral,animaliaBird,animaliaMarineSponge,animaliaInsect,animaliaMammalHuman,fungi,amoebozoa,
    plantae,bacteria,cyanobacteria,plantaeRedAlgae,viruses,archaea,}) => 
      ({animaliaMammal,animaliaCoral,animaliaBird,animaliaMarineSponge,animaliaInsect,animaliaMammalHuman,fungi,amoebozoa,plantae,
    bacteria,cyanobacteria,plantaeRedAlgae,viruses,archaea}))(form);

  const enzClass = (({ one, two }) => ({ one, two }))(form);

    setIsLoading(true);
    setSearchSubmit(false);
    document.getElementById("SearchSuccess").style.display = "block";
    try {
      const response = await axios.post("/searchnomechanism", {
        name: form.name,
        terpType,
        kingdom,
        enzClass,
        uniprot: form.uniprot,
        genbank: form.genbank,
        reactSmiles:
          form.reactSmiles != ""
            ? Buffer.from(form.reactSmiles)
            : form.reactSmiles,
        reactChebi: form.reactChebi,
        reactName: form.reactName,
        prodChebi: form.prodChebi,
        prodName: form.prodName,
        prodSmiles:
          form.prodSmiles != ""
            ? Buffer.from(form.prodSmiles)
            : form.prodSmiles,
        pageCount,
        buttonCount,
      });

      const table = response.data;
      if (table.length > 0) {
        setDataCount(table[0].count);
      } else {
        setDataCount(0);
      }
      return table;
    } catch (err) {
      throw err;
    }

}

export default searchSuggestMechanism