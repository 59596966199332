function InformationPopup({ id, className, children,style }) {
  return (
    <div
      id={id}
      className={className}
      style={style}
    >
      {children}
    </div>
  );
}

export default InformationPopup;
