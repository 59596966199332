import CopySmiles from "./copySmiles.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

function CopySmilesButton({ table, row, copySmiles, substrateOrProduct, isMechanism, cosubstrateSmiles }) {

  const idCopy =
    substrateOrProduct == "substrate"
      ? `substrateSmilesCopy${row}`
      : substrateOrProduct == "cosubstrate" 
      ? `cosubstrateSmilesCopy${row}`
      : `productSmilesCopy${row}`;
  const idCopied =
    substrateOrProduct == "substrate"
      ? `substrateSmilesCopied${row}`
      : substrateOrProduct == "cosubstrate" 
      ? `cosubstrateSmilesCopied${row}`
      : `productSmilesCopied${row}`;
  const idParagraph =
    substrateOrProduct == "substrate"
      ? `substrateSmiles${row}`
      : `productSmiles${row}`;
  const classCopy =
    substrateOrProduct == "substrate"
      ? "substrateSmilesCopy"
      : "productSmilesCopy";
  const classCopied =
    substrateOrProduct == "substrate"
      ? "substrateSmilesCopied"
      : "productSmilesCopied";
  const classList =
    substrateOrProduct == "substrate"
      ? "copySmilesSubstrate"
      : "copySmilesProduct";

      const idCopyMech =
      substrateOrProduct == "substrate"
        ? `substrateSmilesCopyMech${row}`
        : `productSmilesCopyMech${row}`;
    const idCopiedMech =
      substrateOrProduct == "substrate"
        ? `substrateSmilesCopiedMech${row}`
        : `productSmilesCopiedMech${row}`;
        const idParagraphMech =
        substrateOrProduct == "substrate"
          ? `substrateSmilesMech${row}`
          : `productSmilesMech${row}`;


      function handleMouseEnter(idDiv,idDivMech) {
        const popupElement = isMechanism ? document.getElementById(idDivMech) : document.getElementById(idDiv)
        popupElement.style.display = "block";
      }
    
      function handleMouseLeave(idDivCopy,idDivCopied,idDivCopyMech,idDivCopiedMech) {
        document.getElementById(isMechanism ? idDivCopyMech : idDivCopy).style.display = "none";
        document.getElementById(isMechanism ? idDivCopiedMech : idDivCopied).style.display = "none";
      }
    
      function handleClickSmiles(idDivCopy,idDivCopied,idDivCopyMech,idDivCopiedMech) {
        copySmiles(isMechanism ? idParagraphMech : idParagraph)
        document.getElementById(isMechanism ? idDivCopyMech : idDivCopy).style.display = "none";
        document.getElementById(isMechanism ? idDivCopiedMech : idDivCopied).style.display = "block";
      }


  return (
    <li
      style={{ marginLeft: 2, position: "relative" }}
      className={classList}
      onMouseEnter={() => handleMouseEnter(idCopy,idCopyMech)}
      onMouseLeave={() => handleMouseLeave(idCopy,idCopied,idCopyMech,idCopiedMech)}
    >
      <button
        style={{
          border: "none",
          backgroundColor: "rgb(245,245,245)",
          padding: 0,
          margin: 0,
        }}
        onClick={() => handleClickSmiles(idCopy,idCopied,idCopyMech,idCopiedMech)}
      >
        <FontAwesomeIcon icon={faCopy} color="rgb(0,0,0,0.8)" />
      </button>
      <CopySmiles
        copyId={isMechanism ? idCopyMech : idCopy}
        copyClass={classCopy}
        copiedId={isMechanism ? idCopiedMech : idCopied}
        copiedClass={classCopied}
        paragraphId={isMechanism ? idParagraphMech : idParagraph}
      >
        {substrateOrProduct == "substrate"
          ? table[row].substrate_smiles
          : substrateOrProduct === "cosubstrate"
          ? cosubstrateSmiles
          : table[row].product_smiles}
      </CopySmiles>
    </li>
  );
}

export default CopySmilesButton;
