import CheckboxInput from "./chechboxInput.js";

function TypeFilters({ updateForm,searchParams }) {
  return (
    <>
      <CheckboxInput
        idLabel={"diterpinput"}
        idInput={"DiTerpInput"}
        inputText={"Diterpene synthases"}
        name={"di"}
        value={"di"}
        updateForm={updateForm}
        searchParams={searchParams}
      />
      <CheckboxInput
        idLabel={"monoterpinput"}
        idInput={"MonoTerpInput"}
        inputText={"Monoterpene synthases"}
        name={"mono"}
        value={"mono"}
        updateForm={updateForm}
        searchParams={searchParams}
      />
      <CheckboxInput
        idLabel={"sesqterpinput"}
        idInput={"SesqTerpInput"}
        inputText={"Sesquiterpene synthases"}
        name={"sesq"}
        value={"sesq"}
        updateForm={updateForm}
        searchParams={searchParams}
      />
      <CheckboxInput
        idLabel={"triterpinput"}
        idInput={"TriTerpInput"}
        inputText={"Triterpene synthases"}
        name={"tri"}
        value={"tri"}
        updateForm={updateForm}
        searchParams={searchParams}
      />
      <CheckboxInput
        idLabel={"tetraterpinput"}
        idInput={"TetraTerpInput"}
        inputText={"Tetraterpene synthases"}
        name={"tetra"}
        value={"tetra"}
        updateForm={updateForm}
        searchParams={searchParams}
      />
      <CheckboxInput
        idLabel={"sesterterpinput"}
        idInput={"SesterTerpInput"}
        inputText={"Sesterterpene synthases"}
        name={"sester"}
        value={"sester"}
        updateForm={updateForm}
        searchParams={searchParams}
      />
      <CheckboxInput
        idLabel={"sesquarterpinput"}
        idInput={"SesquarTerpInput"}
        inputText={"Sesquarterpene synthases"}
        name={"sesquar"}
        value={"sesquar"}
        updateForm={updateForm}
        searchParams={searchParams}
      />
      <CheckboxInput
        idLabel={"hemiterpinput"}
        idInput={"HemiTerpInput"}
        inputText={"Hemiterpene synthases"}
        name={"hemi"}
        value={"hemi"}
        updateForm={updateForm}
        searchParams={searchParams}
      />
      <CheckboxInput
        idLabel={"prenylterpinput"}
        idInput={"PrenylTerpInput"}
        inputText={"Prenyl transferases"}
        name={"prenyl"}
        value={"pt"}
        updateForm={updateForm}
        searchParams={searchParams}
      />
    </>
  );
}

export default TypeFilters;
