import { Link } from "react-router-dom";

function SuggestionInfo({}) {
  return (
    <div id="fullFormMech">
      <h3
        align="left"
        style={{
          borderBottom: "solid 2px lightgrey",
          paddingBottom: "5px",
        }}
      >
        Suggestions. How does it work?
      </h3>
      <p align="left">
        In order to protect this database from unverified users and their
        untrustworthy entries, we decided to come up with the concept of
        suggestions. Basically, a suggestion is contentwise equivalent of a new
        entry in our database, however protected by so called "editor layer".
        Suggestions are stored and then reviewed by our editors – self-appointed
        experts in the field of terpenesynthases to thoroughly check the content
        of suggestion about to be submitted.
      </p>
      <p
        align="left"
        style={{
          borderBottom: "2px solid lightgrey",
          paddingBottom: "5px",
        }}
      >
        Keen on learning more? Read <Link to="/userroles">here</Link>.
      </p>
    </div>
  );
}

export default SuggestionInfo;
