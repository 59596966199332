function SubmitNewMechanismLink({suggestUnclicked,row, handleSuggestLink}) {
    return (
        <nobr>
        Mechanism:{" "}
        <button
          key={suggestUnclicked}
          id={`suggestMechanism${row}`}
          className="suggestMechanism"
          align="center"
          onClick={()=>handleSuggestLink(row)}
          style={{
            border: "none",
            backgroundColor: "rgb(245,245,245)",
          }}
        >
          <i class="fa fa-comment" id="faCommentMechanism"></i>
        </button>
      </nobr>
    )
}

export default SubmitNewMechanismLink