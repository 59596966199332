import React from "react";
import axios from "../api/axios.js";
import "./Home.css";
import Footer from "../Components/Footer.js";
import HomeNavbar from "../HomeNavbar.js";
import { useState } from "react";
import { useEffect } from "react";
import ReactionTable from "../reactionTable.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageCounter from "../Components/pageCounter/pageCounter.js";
import {
  faAngleDoubleUp,
  faAngleDoubleDown,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import useAuth from "../hooks/useAuth.js";
import MechanismTable from "../mechanismTable.js";
import quickSearch from "../api/quickSearch.js";
import QuickSearchBackground from "../Components/Home/quickSearchBackground.js";
import QuickSearchButton from "../Components/Home/quickSearchButton.js";
import renderData from "../api/renderData.js";
import QuickSearchInput from "../Components/Home/quickSearchInput.js";
import QuickSearchExamples from "../Components/Home/quickSearchExamples.js";
import NextPreviousButtons from "../Components/Home/nextPreviousButtons.js";
import NoResultsMessage from "../Components/noResultsMessage.js";
import SmallLoading from "../Components/smallLoading.js";
import AboutThisProject from "../Components/Home/aboutThisProject.js";
import MechanismWindow from "../Components/MechanismTable/mechanismWindow.js";
import useSearchParamsState from "../hooks/useSearchParamsState.js";
import useDidUpdateEffect from "../hooks/useDidUpdateEffect.js";
import useComment from "../hooks/useComment.js";

function Home() {
  let [count, setCount] = useState(0);
  const [distinctEnzymeCount, setDistinctEnzymeCount] = useState(1335);
  const [distinctReactionCount, setDistinctReactionCount] = useState(2580);
  const [distinctMechanismCount, setDistinctMechanismCount] = useState(1736);
  const [clearClicked, setClearClicked] = useState(false);
  const [firstSearch, setFirstSearch] = useState(true);
  const [mechBody, setMechBody] = useState([]);
  const [table, setTable] = useState([]);
  const [pageCount, setPageCount] = useSearchParamsState("page", 1);
  const [buttonCount, setButtonCount] = useState(1);
  const [dataCount, setDataCount] = useState(0);
  const [searchResult, setSearchResult] = useState([]);
  const [onlyMechanism, setOnlyMechanism] = useSearchParamsState(
    "only_mechanism",
    false
  );
  const [isLoading, setIsLoading] = useState(true);
  const [triggerUseEffect, setTriggerUseEffect] = useState(true);
  const [quickSearchValue, setQuickSearchValue] = useSearchParamsState(
    "search",
    ""
  );
  const [seeAllReactions, setSeeAllReactions] = useSearchParamsState(
    "all_reactions",
    false
  );
  const [interactiveSearchSwitch, setInteractiveSearchSwitch] = useState(false);

  const submitSuggestComm = useComment();
  const { auth } = useAuth();
  const isQuickSearch = true;

  function reloadQuick() {
    setQuickSearchValue("");
    setFirstSearch(true);
    setCount((count = 0));
    if (pageCount === 1) {
      setClearClicked((current) => !current);
    } else {
      setPageCount(1);
      setButtonCount(1);
    }
  }

  function interactiveSearch(attribute) {
    setQuickSearchValue(attribute);
    setCount(0);
    if (pageCount == 1) {
      setTriggerUseEffect((current) => !current);
    } else {
      setInteractiveSearchSwitch((current) => !current);
    }
  }



  async function loadData() {
    const result = await quickSearch(
      seeAllReactions,
      setIsLoading,
      count,
      onlyMechanism,
      quickSearchValue,
      pageCount,
      setDataCount
    );
    const table = result?.table;
    const body = await renderData({
      table,
      isQuickSearch,
      interactiveSearch,
      setMechBody,
    });
    setSearchResult(body);
    setIsLoading(false);
    setTable(result?.table);
    setFirstSearch(true);
  }


  function OnlyMechanism() {
    document.getElementById("notonlymechanism").style.display = "none";
    document.getElementById("onlymechanism").style.display = "block";
    setOnlyMechanism(true);
    setInteractiveSearchSwitch((current) => !current);
  }

  async function NotOnlyMechanism() {
    document.getElementById("notonlymechanism").style.display = "block";
    document.getElementById("onlymechanism").style.display = "none";
    setOnlyMechanism(false);
    setInteractiveSearchSwitch((current) => !current);
  }

  async function seeAll() {
    document.getElementById("hideall").style.display = "block";
    document.getElementById("seeall").style.display = "none";
    setSeeAllReactions(true);
  }

  async function hideAll() {
    count == 0
      ? (document.getElementById("scrollprevious").disabled = true)
      : (document.getElementById("scrollprevious").disabled = false);
    document.getElementById("scrollnext").disabled = false;
    document.getElementById("hideall").style.display = "none";
    document.getElementById("seeall").style.display = "block";
    setSeeAllReactions(false);
  }

  async function loadStats() {
    const stats = await axios.post("/stats");
    setDistinctEnzymeCount(stats.data.enzymeCount[0].enzymeCount);
    setDistinctMechanismCount(stats.data.mechanismCount[0].mechanismCount);
    setDistinctReactionCount(stats.data.reactionCount[0].reactionCount);
  }

  function handlePrevious() {
    setCount((count = count - 1));
    setFirstSearch(false);
    if (seeAllReactions === false) {
      loadData();
    } else {
      setPageCount(Number(pageCount) - 1);
      setButtonCount(Number(buttonCount) - 1);
    }
  }

  function handleNext() {
    setCount((count = count + 1));
    setFirstSearch(false);
    if (seeAllReactions === false) {
      loadData();
    } else {
      setPageCount(Number(pageCount) + 1);
      setButtonCount(Number(buttonCount) + 1);
    }
  }

  function handleClear() {
    setQuickSearchValue("");
    setFirstSearch(true);
    setCount((count = 0));
    if (pageCount === 1) {
      setClearClicked((current) => !current);
    } else {
      setButtonCount(1);
    }
  };

  function handleSearch(event) {
    loadData();
    setFirstSearch(true);
    setPageCount(1);
    setButtonCount(1);
    setCount((count = 0));
    event.preventDefault();
  };

  useEffect(() => {
    loadStats();
  }, []);

  useDidUpdateEffect(() => {
    setButtonCount(1);
    setPageCount(1);
  }, [interactiveSearchSwitch]);

  useEffect(() => {
    loadData();
  }, [
    onlyMechanism,
    seeAllReactions,
    pageCount,
    clearClicked,
    triggerUseEffect,
  ]);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
      </head>
      <body id="body">
        <HomeNavbar reloadQuick={reloadQuick} />

        <QuickSearchBackground
          distinctEnzymeCount={distinctEnzymeCount}
          distinctReactionCount={distinctReactionCount}
          distinctMechanismCount={distinctMechanismCount}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setTriggerUseEffect((current) => !current);
            }}
          >
            <ul
              style={{
                listStyle: "none",
                display: "flex",
                justifyContent: "space-between",
                gap: "0rem",
                padding: 0,
                margin: "0 auto",
              }}
            >
              <li>
                <QuickSearchButton
                  onClick={handleClear}
                  disabled={quickSearchValue?.length == 0 ? true : false}
                  id="quicksearchbutton"
                >
                  Clear
                </QuickSearchButton>
              </li>

              <QuickSearchInput
                quickSearchValue={quickSearchValue}
                setQuickSearchValue={setQuickSearchValue}
                setFirstSearch={setFirstSearch}
              />

              <li>
                <QuickSearchButton
                  onClick={handleSearch}
                  disabled={false}
                  id="quicksearchbutton"
                >
                  Submit
                </QuickSearchButton>
              </li>
            </ul>
          </form>
          <QuickSearchExamples
            setTriggerUseEffect={setTriggerUseEffect}
            setInteractiveSearchSwitch={setInteractiveSearchSwitch}
            setQuickSearchValue={setQuickSearchValue}
          />
        </QuickSearchBackground>

        <section id="quickSearchResult">
          <NextPreviousButtons
            isLoading={isLoading}
            firstSearch={firstSearch}
            dataCount={dataCount}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            count={count}
          />
          <div id="hometable">
            {isLoading ? (
              <SmallLoading />
            ) : searchResult?.length == 0 ? (
              <NoResultsMessage />
            ) : (
              <ReactionTable
                body={searchResult}
                display={"none"}
                commentFunction={submitSuggestComm}
                auth={auth}
                seeAllReactions={seeAllReactions}
              />
            )}
            <ul id="quickSearchDisplayAllResults">
              <li>
                <button
                  id="notonlymechanism"
                  onClick={() => {
                    OnlyMechanism();
                    setFirstSearch(true);
                  }}
                  disabled={searchResult?.length == 0 ? true : false}
                >
                  {" "}
                  Find reactions <br /> with mechanism
                </button>
                <button
                  id="onlymechanism"
                  disabled={searchResult?.length == 0 ? true : false}
                  style={{ display: "none" }}
                  onClick={() => {
                    NotOnlyMechanism();
                    setFirstSearch(true);
                  }}
                >
                  <FontAwesomeIcon icon={faCheck} color="green" /> Find
                  reactions <br /> with mechanism
                </button>
              </li>
              <li>
                <button
                  id="seeall"
                  onClick={seeAll}
                  disabled={searchResult?.length == 0 ? true : false}
                >
                  <FontAwesomeIcon icon={faAngleDoubleDown} /> Display more{" "}
                  <br />
                  reactions
                </button>
                <button
                  id="hideall"
                  disabled={searchResult?.length == 0 ? true : false}
                  style={{ display: "none" }}
                  onClick={hideAll}
                >
                  <FontAwesomeIcon icon={faAngleDoubleUp} /> Hide all <br />{" "}
                  reactions
                </button>
              </li>
            </ul>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {dataCount == 0 ? (
                ""
              ) : seeAllReactions ? (
                <PageCounter
                  dataCount={dataCount}
                  buttonCount={buttonCount}
                  setButtonCount={setButtonCount}
                  pageCount={pageCount}
                  setPageCount={setPageCount}
                  setCount={setCount}
                  count={count}
                />
              ) : (
                <>
                  {count + 1} of {dataCount}
                </>
              )}
            </div>
          </div>
        </section>
        <AboutThisProject />
        <Footer />
      </body>
      <MechanismWindow>
        <MechanismTable
          heading={[
            "Intermediate substrate",
            "Intermediate product",
            "Mechanism type",
          ]}
          body={mechBody}
        />
      </MechanismWindow>
    </html>
  );
}

export default Home;
